<template class="template">

  <body class="img-sfondo-indovina position-sticky">

    <div class="container centramento-contenuto">

      <!-- TITOLI -->
      <div class="row align-items-center justify-content-center margin-title-gioco">   
        <div class="col d-flex jusity-content-center card-titoli">
          <div class="row text-center pe-4 ps-4">

            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano">
              <h1>IL SEGUENTE STATO EUROPEO FA PARTE DELL'UE, VERO O FALSO?</h1>
              <!-- sotto titolo -->
              <h4>Devi indovinare se il seguente stato europeo fa parte dell'Unione Europea oppure no</h4>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese">
              <h1>IS THE FOLLOWING EUROPEAN STATE PART OF THE EU, TRUE OR FALSE?</h1>
              <!-- sotto titolo -->
              <h4>You have to guess whether the following European country is part of the European Union or not</h4>
            </div>

            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese">
              <h1>ONKO SEURAAVA EUROOPAN VALTIO OSA EU, TA TOSI VAI EPÄTOSI?</h1>
              <!-- sotto titolo -->
              <h4>Sinun on arvattava, onko seuraava Euroopan maa osa Euroopan unionia vai ei</h4>
            </div>

          </div>
        </div>
      </div>

      <!-- PARTE INTERATTIVA -->
      <div class="row align-items-center justify-content-center pe-5 ps-5">
        <div class="col d-flex justify-content-center card-gioco align-items-center text-center mt-5 pt-5 pb-5 me-5 ms-5">

          <!-- BOTTONE per INIZIARE -->
          <div class="row pt-5 pb-5 justify-content-center" v-if="this.a < 1">
    
            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> INIZIA </button>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> START </button>
            </div>
            
            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> ALKAA </button>
            </div>
      
          </div>

          <!-- CARD PAGINA FINALE -->
          <div class="row justify-content-center pt-5 pb-5" v-else-if="this.a == this.final_page">

            <!-- ITALIANO -->
            <div class="col" v-if="this.lingua == this.italiano">
              <h5>VALUTAZIONE FINALE</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Punteggio finale: </strong> {{ score }}/{{ total }} </p>
                  <p  class="score-margin"><strong>Totale risposte giuste:</strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p  class="score-margin"><strong>Livello di conoscienza:</strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Come minchia è possibile che hai sbagliato tutte le risposte!?!? Sei proprio ignorante... Vai a studiare che e meglio...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Sei un ignorante!! Vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Sai qualcosina, ma ancora troppo poco, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Non sei arrivato neanche a meta, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, sei arrivatə a metà, ma non ancora alla sufficienza...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Hai una conoscienza basica dell'UE, ma hai ancora molto da imparare</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Discreto, puoi fare di meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Bravo! Hai una buona conoscienza dell'UE, ma puoi fare ancora meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Complimentissimi!! Hai un ottima conoscienza dell'UE!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Bravissimə!! Sai perfettamente quali statti appartengono all'UE e quali no, Ottimo lavoro!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> rincomincia </button>
              </div> 
            </div>
      
            <!-- INGLESE -->
            <div class="col" v-else-if="this.lingua == this.inglese">
              <h5>FINAL EVALUATION</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Final score: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Total right answers: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Level of knowledge: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>How the fuck is it possible that you got all the answers wrong!?!? You're really ignorant... Go study, it's better...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>You are ignorant!! Go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>You know a little, but still too little, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>You haven't even reached your destination, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, you're halfway there, but not enough yet...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>You have a basic understanding of UE, but you still have a lot to learn</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Decent, you can do better!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Good! You have a good knowledge of UE, but you can do even better!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Congratulations!! You have an excellent knowledge of UE!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Well done!! You know perfectly well which states belong to the EU and which do not, Great job!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> start again </button>
              </div>
            </div>

            <!-- FINLANDESE -->
            <div class="col" v-else-if="this.lingua == this.finlandese">
              <h5>LOPPUARVIOINTI</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Lopullinen tulos: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Oikeat vastaukset yhteensä: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Tiedon taso: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Miten helvetissä on mahdollista, että olet saanut kaikki vastaukset väärin!?!? Olet todella tietämätön... Mene opiskelemaan, se on parempi...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Olet tietämätön!! Mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Tiedät vähän, mutta silti liian vähän, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Et ole edes saavuttanut määränpäätäsi, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Mh, olet puolivälissä, mutta ei vielä tarpeeksi...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Sinulla on perusymmärrys UE:stä, mutta sinulla on vielä paljon opittavaa</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Kunnollinen, voit tehdä paremmin!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Hyvä! Sinulla on hyvä tieto UE:stä, mutta pystyt vielä parempaan!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Onnittelut!! Sinulla on erinomainen UE-tieto!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Hyvin tehty!! Tiedät erittäin hyvin mitkä valtiot kuuluvat EU:hun ja mitkä eivät, hienoa työtä!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> aloittaa uudelleen </button>
              </div>
            </div>

          </div>

          <!-- GIOCO -->
          <div v-else class="container-fluid text-center pt-5 pb-5 justify-content-center">

            <!-- CARD VERO O FALSO -->
            <div class="row align-items-center justify-content-center" v-if="this.cp == true">

              <!-- ITALIANO -->
              <div v-if="this.lingua == this.italiano">
                <div v-for="stato in europaIT" class="col text-center">

                  <!-- CARD RIPETUTA -->
                  <div v-if="stato.id == this.id_casuale" class="row text-center">
                    <div class="col">
                      <!-- stato -->
                      <p class="h1 mb-4">{{ stato.Stato.toUpperCase() }}</p>
                      <!-- pulsante vero -->
                      <button @click="vero" class="btn-custom-vero mt-4 mb-4 me-3" type="submit"> VERO </button>
                      <!-- pulsante falso -->
                      <button @click="falso" class="btn-custom-falso mt-4 mb-4 ms-2" type="submit"> FALSO </button>
                    </div>
                  </div>

                </div>
              </div>

              <!-- INGLESE -->
              <div v-else-if="this.lingua == this.inglese">
                <div v-for="stato in europaEN" class="col text-center">

                  <!-- CARD RIPETUTA -->
                  <div v-if="stato.id == this.id_casuale" class="row text-center">
                    <div class="col">
                      <!-- stato -->
                      <p class="h1 mb-4">{{ stato.Stato.toUpperCase() }}</p>
                      <!-- pulsante vero -->
                      <button @click="vero" class="btn-custom-vero mt-4 mb-4 me-3" type="submit"> TRUE </button>
                      <!-- pulsante falso -->
                      <button @click="falso" class="btn-custom-falso mt-4 mb-4 ms-2" type="submit"> FALSE </button>
                    </div>
                  </div>

                </div>
              </div>

              <!-- FINLANDESE -->
              <div v-else-if="this.lingua == this.finlandese">
                <div v-for="stato in europaFI" class="text-center col">

                  <!-- CARD RIPETUTA -->
                  <div v-if="stato.id == this.id_casuale" class="row text-center">
                    <div class="col">
                      <!-- stato -->
                      <p class="h1 mb-4">{{ stato.Stato.toUpperCase() }}</p>
                      <!-- pulsante vero -->
                      <button @click="vero" class="btn-custom-vero mt-4 mb-4 me-3" type="submit"> TOTTA </button>
                      <!-- pulsante falso -->
                      <button @click="falso" class="btn-custom-falso mt-4 mb-4 ms-2" type="submit"> VÄÄRÄ </button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
      
            <!-- CARD di soluzione -->
            <div class="row align-items-center justify-content-center" v-else>

              <!-- SOLUZIONE -->
              <div class="text-center container-fluid">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">

                  <!-- soluzione -->
                  <div v-if="this.UE == 'si'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">SOLUZIONE: </h5>
                      <p> VERO</p>
                    </div>
                  </div>
                  <div v-if="this.UE == 'no'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">SOLUZIONE: </h5>
                      <p> FALSO</p>
                    </div>
                  </div>
                  
                  <!-- commento e aggiunta punti -->
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6">complimenti la risposta era corretta!</span>
                    <p class="pt-3">punti + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorante! La risposta era sbagliata!</span>
                    <p class="pt-3">punti -5</p>
                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
    
                  <!-- soluzione -->
                  <div v-if="this.UE == 'si'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">CORRECT ANSWER: </h5>
                      <p> TRUE</p>
                    </div>
                  </div>
                  <div v-if="this.UE == 'no'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">CORRECT ANSWER: </h5>
                      <p> FALSE</p>
                    </div>
                  </div>
                  
                  <!-- commento e aggiunta punti -->
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">congratulations, the answer was correct!</span>
                    <p class="pt-3">points + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorant! The answer was wrong!</span>
                    <p class="pt-3">points -5</p>
                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  
                  <!-- soluzione -->
                  <div v-if="this.UE == 'si'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">OIKEA VASTAUS: </h5>
                      <p> TOTTA</p>
                    </div>
                  </div>
                  <div v-if="this.UE == 'no'">
                    <div class="orientamento-soluzione pt-3 pb-3">
                      <h5 class="me-2">OIKEA VASTAUS: </h5>
                      <p> VÄÄRÄ</p>
                    </div>
                  </div>
                  
                  <!-- commento e aggiunta punti -->
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">onnittelut, vastaus oli oikea!</span>
                    <p class="pt-3">pisteitä + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Tietämätön! Vastaus oli väärä!</span>
                    <p class="pt-3">pisteitä -5</p>
                  </div>
                </div>
                
              </div>

              <!-- SCORE -->
              <div class="d-flex justify-content-center align-items-center text-center ">

                <!-- ITALIANO -->
                <div class="score-border" v-if="this.lingua == this.italiano">
                  <p class="score-margin">punteggio totale: {{ score }}</p>
                  <p class="score-margin">totale risposte giuste: {{ conoscenza }}</p>
                </div>

                <!-- INGLESE -->
                <div class="score-border" v-else-if="this.lingua == this.inglese">
                  <p class="score-margin">total score: {{ score }}</p>
                  <p class="score-margin">total correct answers: {{ conoscenza }}</p>
                </div>

                <!-- FINLANDESE -->
                <div class="score-border" v-else-if="this.lingua == this.finlandese">
                  <p class="score-margin">kokonaispisteet: {{ score }}</p>
                  <p class="score-margin">täysin oikeat vastaukset: {{ conoscenza }}</p>
                </div>
                
              </div>

              <!-- BOTTONE per CAMBIARE STATO / FINE -->
              <div>
                <!-- CAMBIA STATO -->
                <div v-if="this.a < this.n_stati">
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> prossimo stato </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> next country </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> seuraava maa </button>
                  </div>
                </div>
                <!-- FINE -->
                <div v-else>
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> fine </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> end </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> loppu </button>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- A QUALE STATO SIAMO -->
            <div class="row ">
              <div class="text-center container-fluid" v-if="this.cp == true">
                <p class="mt-4 h5">{{ a }}/{{ n_stati }}</p>
              </div>
              <div class="text-center container-fluid" v-else>
                <p>{{ a }}/{{ n_stati }}</p>
              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>

  </body>

</template>

<script>

  //importo il file json
  import statiItaliano from '/statiItalianoJson.json'
  import statiInglese from '/statiIngleseJson.json'
  import statiFinlandese from '/statiFinlandeseJson.json'

  export default{
    name: 'IndovinaCapitaleEuropaView',
    //importiamo la lingua
    props: ['lingua'],

    data(){
      return{

        //LINGUE
        inglese: "EN",
        italiano: "IT",
        finlandese: "FI",
       
        //per CICLARE i dati degli STATI EUROPEI
          //ITALIANO
          europaIT: statiItaliano.EUROPA,          
          //INGLESE
          europaEN: statiInglese.EUROPA,         
          //FINLANDESE          
          europaFI: statiFinlandese.EUROPA,
        //

        //INTRAPPOLO il VALORE dell'UE
        UE: "",

        //variabile per far vedere una sola card alla volta
        a: 0,

        //variabile per mostrare la pagina finale
        final_page: 0,

        //questa variabile racchiuderà il numero di stati del continente che stiamo usando
        n_stati: 0,

        //array vuoto per inserire gli id degli stati usciti.
        check_state_array_vuoto: [], 

        //PER SALVARE IL NUMERO CASUALE
        numero_casuale: 0,


        //PUNTEGGIO
          //variabile per mostrare il punteggio
          score: 0,
          //variabile per mostrare il punteggio totale
          total: 0,
          //per dire se è giusto o sbagliato
          guess: "",
          //varibile per tenere il conto di quante risposte sono giusto
          conoscenza: 0,
          //calcola la percentuale di risposte giuste
          percentuale: 0,
        //

        //controlla/prossimo stato
        cp: true,
        
      }
    },

    //metodi - funzioni
    methods: {

      //VERO
      vero(){
        //cambia la variabile pc, quindi porta alla schermata per vedere la soluzione che comprende il pulsante per cambiare stato
        this.cp = !this.cp

        //mi da giusto se lo stato fa parte dell'ue
        if(this.UE == "si"){
          this.guess = true
        }
        else{
          this.guess = false
        }

        //aggiunge i punteggi 
        if(this.guess == true){
          this.score = this.score +10
          this.conoscenza = this.conoscenza +1
        }
        else{
          this.score = this.score - 5
        }
      },

      //falso
      falso(){
        //cambia la variabile pc, quindi porta alla schermata per vedere la soluzione che comprende il pulsante per cambiare stato
        this.cp = !this.cp

        //mi da giusto se lo stato NON fa parte dell'ue
        if(this.UE == "no"){
          this.guess = true
        }
        else{
          this.guess = false
        }

        //aggiunge i punteggi 
        if(this.guess == true){
          this.score = this.score +10
          this.conoscenza = this.conoscenza +1
        }
        else{
          this.score = this.score - 5
        }

        console.log(this.falsoNo)
        console.log(this.UE)
      },

      //CAMBIA STATO
      changeState(){

        //per cambiare il valore di a e far scorrere gli stati 
        this.a = this.a + 1

        //per assegnare alla variabile UE il valore preso dal file json
        //italiano
        if(this.lingua == this.italiano){
          this.UE = statiItaliano.EUROPA[this.id_casuale].UE
        }
        else if(this.lingua == this.inglese){
          this.UE = statiInglese.EUROPA[this.id_casuale].UE
        }
        else if(this.lingua == this.finlandese){
          this.UE = statiFinlandese.EUROPA[this.id_casuale].UE
        }
        //serve a determinare quanti stati ci sono nel continente in questione
        this.n_stati = statiItaliano.EUROPA.length - 1

        //cambia la variabile pc, quindi non fa vedere la schermata della soluzione fino a quando non si cliccherà di nuovo il pulsante controlla
        if(this.a > 1){
          this.cp = !this.cp
        }

        //fa tornare i guess a zero
        this.guess = ""

      },

      //NUMERO CASUALE
      numeroCasuale(){

        //serve a determinare quanti stati ci sono nel continente europeo
        this.n_stati = statiItaliano.EUROPA.length - 1

        //mi da un numero casuale tra 1 e la lunghezza dell'array con gli stati (6)
        this.numero_casuale = Math.ceil(Math.random() * ( (this.n_stati) - (1-1)) + (1-1));

        //se l'array dove abbiamo inserito gli id degli stati usciti, include il numero casuale appena uscito allora rifacciamo partire la funzione
        if(this.check_state_array_vuoto.includes(this.numero_casuale)){
          console.log('è gia presente ritira')
          return this.numeroCasuale();
        }
        //se il numero uscito non è contenuto all'interno dell'array allora lo andiamo ad iserire
        else{
          
          //inserisco l'id dello stato estratto nell'array check_state
          this.check_state_array_vuoto.push(this.numero_casuale);
          //console.log(this.check_state_array_vuoto.sort());
          this.id_casuale = this.numero_casuale

        }

      },

      //FINIRE GIOCO
      endGame(){  
        this.UE = ""
        this.guess = ""
        this.cp = true
        this.a = this.a + 1
        //per dare un valore a final_page in modo da mostrare solo la card finale
        this.final_page = this.n_stati + 1
        //per salvare il punteggio totale
        this.total = this.n_stati * 10
        //per dare il valore ppercentuale delle risposte giuste
        this.percentuale = ((this.conoscenza/this.n_stati)*100).toFixed(0)
      },

      //RINCOMINCIA
      rincomincia(){
        this.a = 0
        this.score = 0
        this.conoscenza = 0
        this.final_page = 0
        this.total = 0
        //ripulisce l'array per controllare gli stati
        this.check_state_array_vuoto = [];
      },


      //funzione per evitare l'aggiornamento della pagina quando si cambia stato
      preventEvent(e){
        e.preventDefault();
      }
    },
    
  }

</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-indovina{
  background-image: url("../../assets/carta-da-parti-mondoblu-spazio-531.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.centramento-contenuto{
  padding-top: 7%;
  padding-bottom: 7%;
  padding-left: 5%;
  padding-right: 5%;

}

/* CARD TITOLI */
.card-titoli{ 
  background-color: rgba(189, 220, 249, 0.69);
  border: 5px solid black;
  border-radius: 70px;
  filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
}

/* PARTE GIOCABILE */
  
  /* PULSANTE INIZIA */
  .btn-custom-inizia{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 30px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    font-weight: bold;
  }
  .btn-custom-inizia:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }
  .sperim{
    min-width:max-content;
  }

  /* CARD GIOCO */
  .card-gioco{
    color:  rgba(189, 220, 249, 0.69);
    border: 5px solid rgba(189, 220, 249, 0.69);
    border-radius: 60px;
    filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
    backdrop-filter: blur(10px);
    background-color: rgb(0,0,0,0.6);
    width: 100%;
    height: 570px;
  }

  /* PULSANTI VERO E FALSO */
  .btn-custom-vero{
    background-color: transparent;
    color: green;
    border: 2px solid green;
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.05s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 25px;
    font-weight: bold;
  }
  .btn-custom-vero:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(101, 244, 158, 0.9);
    border: 2px solid black;
    color: black; 
  }
  .btn-custom-falso{
    background-color: transparent;
    color: red;
    border: 2px solid red;
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.05s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 25px;
    font-weight: bold;
  }
  .btn-custom-falso:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(233, 116, 116, 0.9);
    border: 2px solid black;
    color: black; 
  }

  /* PULSANTI CONTROLLA, PROSSIMO STATO, FINE e RICOMINCIA */
  .btn-custom{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
  }
  .btn-custom:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }

  /* AVVISO di CONTROLLO */
  .advertise-custom{
    background-color: rgba(252, 107, 107, 0.45);
    backdrop-filter: blur(10px);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    border: 2px solid rgba(189, 220, 249, 0.69);
  }

  /* scritta soluzione */
  .orientamento-soluzione{
    display: block;
    justify-content: center;
  }

  /* AVVISO RISPOSTA CORRETTA O SBAGLIATA */
  .correct-answer{
    background-color: rgba(13, 255, 0, 0.284); 
    padding: 5px 10px 5px 10px;
  }
  .wrong-answer{
    background-color: rgba(254, 6, 2, 0.311); 
    padding: 5px 10px 5px 10px;
  }

  /* bordo punteggi */
  .score-border{
    border: 2px solid rgba(189, 220, 249, 0.69);
    max-width: max-content;
    border-radius: 50px;
  }
  .score-margin{
    margin: 10px 15px 10px 15px;
  }
/*  */



@media (min-width: 270px) { 
  .card-gioco{
  height: 530px;
  }
}
@media (min-width: 300px) {  
  .margin-title-gioco{
  padding-top: 23%;
  }
}
@media (min-width: 400px) { 
  .card-gioco{
    width: 80%;
  }
  .margin-title-gioco{
    padding-top: 20%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 470px) { 
  .margin-title-gioco{
    padding-top: 15%;
  }
}
@media (min-width: 520px) { 
  .card-gioco{
    height: 430px;
    border-radius: 100px;
  }
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  }
  .advertise-custom{
    border-radius: 100px;
  }
}
@media (min-width: 570px) { 
  .margin-title-gioco{
    padding-top: 10%;
  }
}
@media (min-width: 600px) { 
  .centramento-contenuto{
    padding-left: 5%;
    padding-right: 5%;
  }
  .orientamento-soluzione{
    display: flex;
  }
}
@media (min-width: 770px) { 
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  } 
  .margin-title-gioco{
    padding-top: 8%;
  }
  .card-titoli{
    border-radius: 80px;
  }
}
@media (min-width: 992px) { 
  .margin-title-gioco{
    padding-top: 3%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 1000px) { 
  .centramento-contenuto{
    padding-left: 15%;
    padding-right: 15%;
  }
}



</style>