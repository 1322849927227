<template>

    <body class="img-sfondo-stdio pb-5" >

        

        <!-- ITALINO -->
        <div class="tutto">

            <!-- TITOLO -->
            <div class="margin-title-s d-flex justify-content-center">
                <h1 v-if="this.lingua == 'IT'" class="studio-title text-center">STUDIO</h1>
                <h1 v-if="this.lingua == 'EN'" class="studio-title text-center">STUDY</h1>
                <h1 v-if="this.lingua == 'FI'" class="studio-title text-center">TUTKIMUS</h1>
            </div>

            <!-- DESCRIZIONE -->
            <div class="margin-title-sub d-flex justify-content-center text-wrap">
                <!-- ITALIANO -->
                <div v-if="this.lingua == 'IT'" class="studio-title text-center">
                    <h5 class="testo-a-capo">Esistono 205 stati nel mondo e 51 territori dipendenti.<br>
                        Dei 205 stati solo 196 sono riconosciuti come "stati sovrani" a livello internazionale.<br>
                        Dei stati non sovrani 5 hanno un riconoscimento parziale da parte dell'ONU (Abcasia, Cipro del Nord, Kosovo, Ossezia del sud e Sahara Occidentale), 2 non hanno nessun riconoscimento (Somaliland e Transnistra) e 2 sono stati in libera associazione con la Nuova Zelanda.<br>
                        Dei 196 "stati sovrani", solo 193 fanno parte dell'ONU; dei 3 stati che non ne fanno parte, 1 (Taiwan) è un ex membro dell'ONU e 2 (la Palestina e la Città del Vaticano) sono osservatori permanenti dell'ONU.<br>
                        In questo sito sono presenti solo i 196 stati considerati "sovrani".
                    </h5>
                </div>
                <!-- INGLESE -->
                <div v-if="this.lingua == 'EN'" class="studio-title text-center">
                    <h5 class="testo-a-capo">There are 205 states in the world and 51 dependent territories.<br>
                        Of the 205 states only 196 are recognized as "sovereign states" internationally.<br>
                        Of the non-sovereign states, 5 have partial recognition by the UN (Abkhazia, Northern Cyprus, Kosovo, South Ossetia and Western Sahara), 2 have no recognition (Somaliland and Transnistra) and 2 are states in free association with the New Zealand.<br>
                        Of the 196 "sovereign states", only 193 are part of the UN; of the 3 states that are not part of it, 1 (Taiwan) is a former member of the UN and 2 (Palestine and Vatican City) are permanent observers of the UN.<br>
                        On this site there are only the 196 states considered "sovereign".
                    </h5>
                </div>
                <!-- FINLANDESE -->
                <div v-if="this.lingua == 'FI'" class="studio-title text-center">
                    <h5 class="testo-a-capo">Maailmassa on 205 osavaltiota ja 51 riippuvaista aluetta.<br>
                        205 osavaltiosta vain 196 on tunnustettu kansainvälisesti "suvereeneiksi valtioiksi".<br>
                        Ei-suvereeneista valtioista viidellä on YK:n osittainen tunnustaminen (Abhasia, Pohjois-Kypros, Kosovo, Etelä-Ossetia ja Länsi-Sahara), kahdella ei ole tunnustamista (Somalimaa ja Transnistra) ja kaksi on valtioita, jotka ovat vapaasti assosioituneita Uuden-Seelannin kanssa. <br>
                        196 "suvereenista valtiosta" vain 193 on osa YK:ta; kolmesta valtiosta, jotka eivät kuulu siihen, yksi (Taiwan) on YK:n entinen jäsen ja 2 (Palestiina ja Vatikaanikaupunki) on pysyviä YK:n tarkkailijoita.<br>
                        Tällä sivustolla on vain 196 valtiota, joita pidetään "suvereeneina".
                    </h5>
                </div>
            </div>

            <!-- COMPONENTE della CARD --> 
            <div>      
                <div v-if="this.lingua == 'IT'">
                    <div v-for="cont in continentiIT">
                        <cardStudioIT :continente="cont.cont"/>
                    </div>
                </div>
                <div v-if="this.lingua == 'EN'">
                    <div v-for="cont in continentiEN">
                        <cardStudioEN :continente="cont.cont"/>
                    </div>
                </div>
                <div v-if="this.lingua == 'FI'">
                    <div v-for="cont in continentiFI">
                        <cardStudioFI :continente="cont.cont"/>
                    </div>
                </div>       
            </div>

        </div>

    </body>

</template>

<script>

    //IMPORT COMPONENTE
    import cardStudioIT from '../components/cardStudioIT.vue'
    import cardStudioEN from '../components/cardStudioEN.vue'
    import cardStudioFI from '../components/cardStudioFI.vue'
    
    

    //importo i file json
        import statiItaliano from '/statiItalianoJson.json'
        import statiInglese from '/statiIngleseJson.json'
        import statiFinlandese from '/statiFinlandeseJson.json'
    //



    export default{
        name: 'StudioView',
        //importiamo la lingua
        props: ['lingua'],

        //importo swiper
        components: {
            
            //per il componente delle cards
            cardStudioIT,
            cardStudioEN,
            cardStudioFI,
        },
       

        data(){
            return{
                //CICLARE i dati di ogni CONTINENTE
                    //ITALIANO
                    continentiIT: [
                        {id:1, cont:statiItaliano.AFRICA,},
                        {id:1, cont:statiItaliano.ASIA,},
                        {id:1, cont:statiItaliano.EUROPA,},
                        {id:1, cont:statiItaliano.NORD_AMERICA,},
                        {id:1, cont:statiItaliano.OCEANIA,},
                        {id:1, cont:statiItaliano.SUD_AMERICA,},
                        {id:1, cont:statiItaliano.USA,},
                    ],
                    //INGLESE
                    continentiEN: [
                        {id:1, cont:statiInglese.AFRICA,},
                        {id:1, cont:statiInglese.ASIA,},
                        {id:1, cont:statiInglese.EUROPA,},
                        {id:1, cont:statiInglese.NORD_AMERICA,},
                        {id:1, cont:statiInglese.OCEANIA,},
                        {id:1, cont:statiInglese.SUD_AMERICA,},
                        {id:1, cont:statiInglese.USA,},
                    ],
                    //FINLANDESE
                    continentiFI: [
                        {id:1, cont:statiFinlandese.AFRICA,},
                        {id:1, cont:statiFinlandese.ASIA,},
                        {id:1, cont:statiFinlandese.EUROPA,},
                        {id:1, cont:statiFinlandese.NORD_AMERICA,},
                        {id:1, cont:statiFinlandese.OCEANIA,},
                        {id:1, cont:statiFinlandese.SUD_AMERICA,},
                        {id:1, cont:statiFinlandese.USA,},
                    ],

            }

        }
    }

</script>

<style>

    /* body e immagine di sfondo */
    body {
    margin: 0;
    padding: 0;
    height: max-content;
    width: 100%;
    }
    .img-sfondo-stdio{
    background-image: url("../assets/map_world_sfondo.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    min-width: max-content;
    height: 100%;
    padding: 0;
    margin: 0;
    }
    .tutto{
        box-sizing: content-box;
        min-width: max-content;
    }

    /* TITOLO e testo sottotitolo*/
    .margin-title-s{
    padding-top: 28%;
    padding-bottom: 5%;
    }
    .studio-title{
        color: black;
        background-color: rgba(189, 220, 249, 0.69);
        backdrop-filter: blur(10px);
        border: 5px solid black;
        border-radius: 100px;
        font-weight: bold;
        font-size: 20px;
        
        filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
        padding: 10px 40px 10px 40px;  
    }
    .margin-title-sub{
    padding-bottom: 5%;
    }
    .studio-subtitle{
        color: black;
        background-color: rgba(189, 220, 249, 0.69);
        backdrop-filter: blur(10px);
        border: 5px solid black;
        border-radius: 100px;
        font-weight: bold;
        font-size: 20px;
        
        filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
        padding: 10px 40px 10px 40px;  
    }
    .testo-a-capo{
        width: 30vh;
        font-size: 15px;
        word-wrap:break-word;
    }

    /* CARDS */
    .swiper {  
        width: 170px;
        height: 450px;
        padding: 3% 10% ;
        box-sizing: content-box;
    }
    .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    /* font-size: 22px; */
    color: rgba(189, 220, 249, 0.69);
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(20px);
    border: solid 5px rgba(189, 220, 249, 0.69);
    /* box-sizing: content-box; */
    padding-top: 5%;
    }

    /* titolo prima card */
    .titolo-continente-custom{
        background-color: transparent;
        backdrop-filter: blur(20px);
        border: 5px solid  rgba(189, 220, 249, 0.69);
        border-radius: 100px;
        width: max-content;
        padding: 20% 30%;
        margin-bottom: 5%;
    }
    .size-title{
        font-size: 20px;
        font-weight: bold;
    }
    .size-title-america{
        font-size: 15px;
        font-weight: bold;
    }

    /* parte scritta */
    /* .sottolineamento-stato{
        border-bottom: 2px solid rgba(189, 220, 249, 0.69);
        width: max-content;
        margin-bottom: 20px;
        font-size: 22px;
    } */
    .size-capitale{
        font-size: 18px;
        margin: 0 5px;
    }

    /* IMMAGINI */
    .centramento-immagini-studio{
        margin-top: 20px;
    }
    .img-bandiera-size{  
        width: 150px;
    }
    .img-mappa-border{
        border: 8px solid white;
        border-radius: 20px;
        width: 130px;
        max-height: 160px;
    }


    /* media query */
    @media (min-width: 280px) {  
        .swiper {  

            padding: 3% 20% ;
        }
    }
    @media (min-width: 300px) { 
        
        .studio-title{
            font-size: 30px;
            padding: 10px 50px 10px 50px;
        }
        .margin-title-s{
            padding-top: 23%;
        }   
    }
    @media (min-width: 334px) { 
        .swiper {  
            padding: 3% 25% ;
        }
        .testo-a-capo{
            width: 40vh;
        } 
    }
    @media (min-width: 390px) {  
    .studio-title{
        font-size: 35px;
        padding: 10px 60px 10px 60px;
    }
    
    }
    @media (min-width: 418px) { 
        .margin-title-s{
            padding-top: 20%;
        }
        .studio-title{
            font-size: 30px;
            padding: 10px 70px 10px 70px;
        }
        .titolo-continente-custom{
            padding: 15% 15%; 
        }
        .size-title{
            font-size: 23px;
        }
        .size-title-america{
            font-size: 18px;
        }
        .swiper {  
        
            padding: 3% 30% ;
        }
    
    }
    @media (min-width: 470px) { 
        .testo-a-capo{
        width: 50vh;
        font-size: 20px;
        }
    .margin-title-s{
        padding-top: 18%;
    }
    
    }
    @media (min-width: 500px) { 
        
        .size-title{
            font-size: 30px;
        }
        .size-title-america{
            font-size: 22px;
        }
        .swiper {  
            width: 200px;
            height: 450px;
            padding: 3% 30% ;
        }
        .sottolineamento-stato{
            font-size: 25px;
        }
        .size-capitale{
            font-size: 20px;
        }
        .img-bandiera-size{  
            width: 170px;   
        }
        .img-mappa-border{
            width: 150px;
            max-height: 160px;
        }
    }
    @media (min-width: 570px) { 
    .margin-title-s{
        padding-top: 15%;
    }
    .swiper {  
            width: 220px;
            height: 450px;
            padding: 3% 30% ;
        }
    }
    @media (min-width: 620px) { 
        .centramento-immagini-studio{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0px;
        }
        .testo-a-capo{
            width: 50vh;
        }
        .studio-title{
            font-size: 55px;
            padding: 10px 80px 10px 80px;
        }
        .titolo-continente-custom{
            padding: 20% 20%; 
        }
        .size-title{
            font-size: 35px;
        }
        .size-title-america{
            font-size: 30px;
        }
        .swiper {  
            width: 300px;
            height: 300px;
            padding: 3% 26% ;
        }
        .img-bandiera-size{
            margin-top: 7%;
            margin-bottom: 1%;
            width: 150px;
        }
        .img-mappa-border{
            width: 115px;
            margin-top: 1%;
            margin-bottom: 1%;
        }
    }
    @media (min-width: 794px){
        
        .studio-title{
            font-size: 45px;
            padding: 10px 90px 10px 90px;
        }
        .margin-title-s{
            padding-top: 15%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 5%;
        }
        .swiper {  
            padding: 3% 25% ;
            width: 350px;
            height: 320px;
        }
        .img-bandiera-size{
            margin-top: 7%;
            margin-bottom: 1%;
            width: 180px;
        }
        .img-mappa-border{
            width: 130px;
            margin-top: 1%;
            margin-bottom: 1%;
        }
        
    }
    @media (min-width: 992px) { 
        .margin-title-s{
            padding-top: 13%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 5%;
        }
        .studio-title{
            padding: 10px 100px 10px 100px;
        }
    
        .titolo-continente-custom{
            padding: 20% 30%; 
        }
        .size-title{
            font-size: 40px;
        }
        .size-title-america{
            font-size: 35px;
        }
        .swiper {  
            width: 400px;
            height: 350px;
        }
        .img-bandiera-size{
            
            width: 200px;
        }
        .img-mappa-border{
            width: 155px;
        }
    }
    @media (min-width: 1247px) { 
        .studio-title{
            font-size: 50px;
        }
        .margin-title-s{
            padding-top: 8%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 3%;
        }
        .swiper {  
            width: 500px;
            height: 430px;
        }
        .img-bandiera-size{
            
            width: 250px;
        }
        .img-mappa-border{
            width: 200px;
        }
    }



</style>