<template>

    <div>
        <swiper :effect="'cards'"    :grabCursor="false"    :modules="modules"    class="mySwiper carte-continenti" >
                
            <swiper-slide  v-for="stato in continente">
                
                <!-- pagina iniziale -->
                <div  class="d-flex justify-content-center" v-if="stato.id == 0">
                    <div class="titolo-continente-custom text-center">
                        <h2 v-if="this.continente[0].continente == 'AFRICA'" class="size-title">AFRICA</h2>
                        <h2 v-if="this.continente[0].continente == 'ASIA'" class="size-title">ASIA</h2>
                        <h2 v-if="this.continente[0].continente == 'EUROPA'" class="size-title">EUROPE</h2>
                        <h2 v-if="this.continente[0].continente == 'NORD_AMERICA'" class="size-title">NORTH AMERICA</h2>
                        <h2 v-if="this.continente[0].continente == 'OCEANIA'" class="size-title">OCEANIA</h2>
                        <h2 v-if="this.continente[0].continente == 'SUD_AMERICA'" class="size-title">SOUTH AMERICA</h2>
                        <h2 v-if="this.continente[0].continente == 'USA'" class="size-title">U.S.A.</h2>
                    </div>
                </div>

                <!-- cards -->
                <div  v-else="stato.id != 0" class="text-center">

                    <!-- parte scritta -->
                    <div class="d-flex justify-content-center">
                        <h2 class="sottolineamento-stato">{{ stato.Stato.toUpperCase() }}</h2>
                    </div>

                    <div v-if="this.continente[0].continente != 'USA'" class="d-flex justify-content-center">
                        <h3 class="size-capitale">CAPITAL: {{ stato.Capitale }}</h3>
                    </div>
                    
                    <!-- immagini -->
                    <!-- AFRICA -->
                    <div v-if="this.continente[0].continente == 'AFRICA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeAfrica">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereAfrica">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- ASIA -->
                    <div v-if="this.continente[0].continente == 'ASIA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeAsia">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereAsia">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- EUROPA -->
                    <div v-if="this.continente[0].continente == 'EUROPA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeEuropa">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereEuropa">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- NORD AMERICA -->
                    <div v-if="this.continente[0].continente == 'NORD_AMERICA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeNordAmerica">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereNordAmerica">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- OCEANIA -->
                    <div v-if="this.continente[0].continente == 'OCEANIA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeOceania">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereOceania">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- SUD AMERICA -->
                    <div v-if="this.continente[0].continente == 'SUD_AMERICA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeSudAmerica">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                            <div v-for="bandiera in BandiereSudAmerica">
                                <div v-if="bandiera.id == stato.id">
                                    <img class="img-bandiera-size" :src="bandiera.imgBandiera" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <!-- USA -->
                    <div v-if="this.continente[0].continente == 'USA'">
                        <div class="centramento-immagini-studio">
                            <div v-for="mappa in mappeUSA">
                                <div v-if="mappa.id == stato.id">
                                    <img class="img-mappa-border" :src="mappa.imgMappa" :alt="stato.alt_bandiera">
                                </div>                          
                            </div>
                        </div>
                    </div>

                </div>
                    
            </swiper-slide>
        
        </swiper>
    </div>
</template>

<script>
    //SWIPER
        // Import Swiper Vue.js components
        import { Swiper, SwiperSlide } from 'swiper/vue';
        // import swiper cards effect
        import 'swiper/css/effect-cards';
        // Import Swiper styles
        import 'swiper/css';
        // import required modules
        import { EffectCards } from 'swiper/modules';
    //

    //importo i file json
        import statiItaliano from '../../statiItalianoJson.json'       
    //

    //importiamo le immagini delle bandiere
        //AFRICA
        import imgAlgeriaB from "../assets/bandiere/bandiere-africa/icons8-algeria-100.png"
        import imgAngolaB from "../assets/bandiere/bandiere-africa/icons8-angola-100.png"
        import imgBeninB from "../assets/bandiere/bandiere-africa/icons8-benin-100.png"
        import imgBotswanaB from "../assets/bandiere/bandiere-africa/icons8-botswana-100.png"
        import imgBurkinaFasoB from "../assets/bandiere/bandiere-africa/icons8-burkina-faso-100.png"
        import imgBurundiB from "../assets/bandiere/bandiere-africa/icons8-burundi-100.png"
        import imgCamerunB from "../assets/bandiere/bandiere-africa/icons8-camerun-100.png"
        import imgCapoVerdeB from "../assets/bandiere/bandiere-africa/icons8-capo-verde-100.png"
        import imgCiadB from "../assets/bandiere/bandiere-africa/icons8-ciad-100.png"
        import imgComoreB from "../assets/bandiere/bandiere-africa/icons8-comore-100.png"
        import imgCongoB from "../assets/bandiere/bandiere-africa/icons8-congo-100.png"
        import imgCostaDAvorioB from "../assets/bandiere/bandiere-africa/icons8-costa-d-avorio-100.png"
        import imgEgittoB from "../assets/bandiere/bandiere-africa/icons8-egitto-100.png"
        import imgEritreaB from "../assets/bandiere/bandiere-africa/icons8-eritrea-100.png"
        import imgEtiopiaB from "../assets/bandiere/bandiere-africa/icons8-etiopia-100.png"
        import imgGabonB from "../assets/bandiere/bandiere-africa/icons8-gabon-100.png"
        import imgGambiaB from "../assets/bandiere/bandiere-africa/icons8-gambia-100.png"
        import imgGhanaB from "../assets/bandiere/bandiere-africa/icons8-ghana-100.png"
        import imgGibutiB from "../assets/bandiere/bandiere-africa/icons8-gibuti-100.png"
        import imgGuineaB from "../assets/bandiere/bandiere-africa/icons8-guinea-100.png"
        import imgGuineaBissauB from "../assets/bandiere/bandiere-africa/icons8-guinea-bissau-100.png"
        import imgGuineaEquatorialeB from "../assets/bandiere/bandiere-africa/icons8-guinea-equatoriale-100.png"
        import imgKeniaB from "../assets/bandiere/bandiere-africa/icons8-kenia-100.png"
        import imgLesothoB from "../assets/bandiere/bandiere-africa/icons8-lesotho-100.png"
        import imgLiberiaB from "../assets/bandiere/bandiere-africa/icons8-liberia-100.png"
        import imgLibiaB from "../assets/bandiere/bandiere-africa/icons8-libia-100.png"
        import imgMadagascarB from "../assets/bandiere/bandiere-africa/icons8-madagascar-100.png"
        import imgMalawiB from "../assets/bandiere/bandiere-africa/icons8-malawi-100.png"
        import imgMaliB from "../assets/bandiere/bandiere-africa/icons8-mali-100.png"
        import imgMaroccoB from "../assets/bandiere/bandiere-africa/icons8-marocco-100.png"
        import imgMauritaniaB from "../assets/bandiere/bandiere-africa/icons8-mauritania-100.png"
        import imgMauritiusB from "../assets/bandiere/bandiere-africa/icons8-mauritius-100.png"
        import imgMozambicoB from "../assets/bandiere/bandiere-africa/icons8-mozambico-100.png"
        import imgNamibiaB from "../assets/bandiere/bandiere-africa/icons8-namibia-100.png"
        import imgNigerB from "../assets/bandiere/bandiere-africa/icons8-niger-100.png"
        import imgNigeriaB from "../assets/bandiere/bandiere-africa/icons8-nigeria-100.png"
        import imgRepubblicaCentrafricanaB from "../assets/bandiere/bandiere-africa/icons8-repubblica-centrafricana-100.png"
        import imgRepubblicaDemocraticaDelCongoB from "../assets/bandiere/bandiere-africa/icons8-repubblica-democratica-del-congo-100.png"
        import imgRuandaB from "../assets/bandiere/bandiere-africa/icons8-ruanda-100.png"
        import imgSaoTomeEPrincipeB from "../assets/bandiere/bandiere-africa/icons8-sao-tome-e-principe-100.png"
        import imgSenegalB from "../assets/bandiere/bandiere-africa/icons8-senegal-100.png"
        import imgSeychellesB from "../assets/bandiere/bandiere-africa/icons8-seychelles-100.png"
        import imgSierraLeoneB from "../assets/bandiere/bandiere-africa/icons8-sierra-leone-100.png"
        import imgSomaliaB from "../assets/bandiere/bandiere-africa/icons8-somalia-100.png"
        import imgSudAfricaB from "../assets/bandiere/bandiere-africa/icons8-sud-africa-100.png"
        import imgSudanB from "../assets/bandiere/bandiere-africa/icons8-sudan-100.png"
        import imgSudanDelSudB from "../assets/bandiere/bandiere-africa/icons8-sudan-del-sud-100.png"
        import imgSwazilandB from "../assets/bandiere/bandiere-africa/icons8-swaziland-100.png"
        import imgTanzaniaB from "../assets/bandiere/bandiere-africa/icons8-tanzania-100.png"
        import imgTogoB from "../assets/bandiere/bandiere-africa/icons8-togo-100.png"
        import imgTunisiaB from "../assets/bandiere/bandiere-africa/icons8-tunisia-100.png"
        import imgUgandaB from "../assets/bandiere/bandiere-africa/icons8-uganda-100.png"
        import imgZambiaB from "../assets/bandiere/bandiere-africa/icons8-zambia-100.png"
        import imgZimbabweB from "../assets/bandiere/bandiere-africa/icons8-zimbabwe-100.png"

        //ASIA
        import imgAfghanistanB from "../assets/bandiere/bandiere-asia/icons8-afghanistan-100.png"
        import imgArabiaSauditaB from "../assets/bandiere/bandiere-asia/icons8-arabia-saudita-100.png"
        import imgArmeniaB from "../assets/bandiere/bandiere-asia/icons8-armenia-100.png"
        import imgAzeraigianB from "../assets/bandiere/bandiere-asia/icons8-azerbaigian-100.png"
        import imgBahreinB from "../assets/bandiere/bandiere-asia/icons8-bahrein-100.png"
        import imgBangladeshB from "../assets/bandiere/bandiere-asia/icons8-bangladesh-100.png"
        import imgBhutanB from "../assets/bandiere/bandiere-asia/icons8-bhutan-100.png"
        import imgBirmaniaB from "../assets/bandiere/bandiere-asia/icons8-birmania-100.png"
        import imgBruneiB from "../assets/bandiere/bandiere-asia/icons8-brunei-100.png"
        import imgCambogiaB from "../assets/bandiere/bandiere-asia/icons8-cambogia-100.png"
        import imgCinaB from "../assets/bandiere/bandiere-asia/icons8-cina-100.png"
        import imgCoreaDelNordB from "../assets/bandiere/bandiere-asia/icons8-corea-del-nord-100.png"
        import imgCoreaDelSudB from "../assets/bandiere/bandiere-asia/icons8-corea-del-sud-100.png"
        import imgEmiratiArabiUnitiB from "../assets/bandiere/bandiere-asia/icons8-emirati-arabi-uniti-100.png"
        import imgFilippineB from "../assets/bandiere/bandiere-asia/icons8-filippine-100.png"
        import imgGeorgiaB from "../assets/bandiere/bandiere-asia/icons8-georgia-100.png"
        import imgGiapponeB from "../assets/bandiere/bandiere-asia/icons8-giappone-100.png"
        import imgGiordaniaB from "../assets/bandiere/bandiere-asia/icons8-giordania-100.png"
        import imgIndiaB from "../assets/bandiere/bandiere-asia/icons8-india-100.png"
        import imgIndonesiaB from "../assets/bandiere/bandiere-asia/icons8-indonesia-100.png"
        import imgIranB from "../assets/bandiere/bandiere-asia/icons8-iran-100.png"
        import imgIraqB from "../assets/bandiere/bandiere-asia/icons8-iraq-100.png"
        import imgIsraeleB from "../assets/bandiere/bandiere-asia/icons8-israele-100.png"
        import imgKazakistanB from "../assets/bandiere/bandiere-asia/icons8-kazakistan-100.png"
        import imgKirghizistanB from "../assets/bandiere/bandiere-asia/icons8-kirghizistan-100.png"
        import imgKuwaitB from "../assets/bandiere/bandiere-asia/icons8-kuwait-100.png"
        import imgLaosB from "../assets/bandiere/bandiere-asia/icons8-laos-100.png"
        import imgLibanoB from "../assets/bandiere/bandiere-asia/icons8-libano-100.png"
        import imgMalaysiaB from "../assets/bandiere/bandiere-asia/icons8-malaysia-100.png"
        import imgMaldiveB from "../assets/bandiere/bandiere-asia/icons8-maldive-100.png"
        import imgMongoliaB from "../assets/bandiere/bandiere-asia/icons8-mongolia-100.png"
        import imgNepalB from "../assets/bandiere/bandiere-asia/icons8-nepal-100.png"
        import imgOmanB from "../assets/bandiere/bandiere-asia/icons8-oman-100.png"
        import imgPakistanB from "../assets/bandiere/bandiere-asia/icons8-pakistan-100.png"
        import imgPalestinaB from "../assets/bandiere/bandiere-asia/icons8-palestina-100.png"
        import imgQatarB from "../assets/bandiere/bandiere-asia/icons8-qatar-100.png"
        import imgRussiaB from "../assets/bandiere/bandiere-asia/icons8-russia-100.png"
        import imgSingaporeB from "../assets/bandiere/bandiere-asia/icons8-singapore-100.png"
        import imgSiriaB from "../assets/bandiere/bandiere-asia/icons8-siria-100.png"
        import imgSriLankaB from "../assets/bandiere/bandiere-asia/icons8-sri-lanka-100.png"
        import imgTagikistanB from "../assets/bandiere/bandiere-asia/icons8-tagikistan-100.png"
        import imgTaiwanB from "../assets/bandiere/bandiere-asia/icons8-taiwan-100.png"
        import imgThailandiaB from "../assets/bandiere/bandiere-asia/icons8-thailandia-100.png"
        import imgTimorEstB from "../assets/bandiere/bandiere-asia/icons8-timor-est-100.png"
        import imgTurkmenistanB from "../assets/bandiere/bandiere-asia/icons8-turkmenistan-100.png"
        import imgUzbekistanB from "../assets/bandiere/bandiere-asia/icons8-uzbekistan-100.png"
        import imgVietnamB from "../assets/bandiere/bandiere-asia/icons8-vietnam-100.png"
        import imgYemenB from "../assets/bandiere/bandiere-asia/icons8-yemen-100.png"

        //EUROPA
        import imgAlbaniaB from "../assets/bandiere/bandiere-europa/icons8-albania-100.png"
        import imgAndorraB from "../assets/bandiere/bandiere-europa/icons8-andorra-100.png"
        import imgAustriaB from "../assets/bandiere/bandiere-europa/icons8-austria-100.png"
        import imgBelarusB from "../assets/bandiere/bandiere-europa/icons8-belarus-100.png"
        import imgBelgioB from "../assets/bandiere/bandiere-europa/icons8-belgio-100.png"
        import imgBosniaEdErzegovinaB from "../assets/bandiere/bandiere-europa/icons8-bosnia-ed-erzegovina-100.png"
        import imgBulgariaB from "../assets/bandiere/bandiere-europa/icons8-bulgaria-100.png"
        import imgCiproB from "../assets/bandiere/bandiere-europa/icons8-cipro-100.png"
        import imgCittaDelVaticanoB from "../assets/bandiere/bandiere-europa/icons8-città-del-vaticano-100.png"
        import imgCroaziaB from "../assets/bandiere/bandiere-europa/icons8-croazia-100.png"
        import imgDanimarcaB from "../assets/bandiere/bandiere-europa/icons8-danimarca-100.png"
        import imgEstoniaB from "../assets/bandiere/bandiere-europa/icons8-estonia-100.png"
        import imgFinlandiaB from "../assets/bandiere/bandiere-europa/icons8-finlandia-100.png"
        import imgFranciaB from "../assets/bandiere/bandiere-europa/icons8-francia-100.png"
        import imgGermaniaB from "../assets/bandiere/bandiere-europa/icons8-germania-100.png"
        import imgGreciaB from "../assets/bandiere/bandiere-europa/icons8-grecia-100.png"
        import imgIrlandaB from "../assets/bandiere/bandiere-europa/icons8-irlanda-100.png"
        import imgIslandaB from "../assets/bandiere/bandiere-europa/icons8-islanda-100.png"
        import imgItaliaB from "../assets/bandiere/bandiere-europa/icons8-italia-100.png"
        import imgLettoniaB from "../assets/bandiere/bandiere-europa/icons8-lettonia-100.png"
        import imgLiechtensteinB from "../assets/bandiere/bandiere-europa/icons8-liechtenstein-100.png"
        import imgLituaniaB from "../assets/bandiere/bandiere-europa/icons8-lituania-100.png"
        import imgLussemburgoB from "../assets/bandiere/bandiere-europa/icons8-lussemburgo-100.png"
        import imgMacedoniaB from "../assets/bandiere/bandiere-europa/icons8-macedonia-100.png"
        import imgMaltaB from "../assets/bandiere/bandiere-europa/icons8-malta-100.png"
        import imgMoldovaB from "../assets/bandiere/bandiere-europa/icons8-moldova-100.png"
        import imgMonacoB from "../assets/bandiere/bandiere-europa/icons8-monaco-100.png"
        import imgMontenegroB from "../assets/bandiere/bandiere-europa/icons8-montenegro-100.png"
        import imgNorvegiaB from "../assets/bandiere/bandiere-europa/icons8-norvegia-100.png"
        import imgPaesiBassiB from "../assets/bandiere/bandiere-europa/icons8-paesi-bassi-100.png"
        import imgPoloniaB from "../assets/bandiere/bandiere-europa/icons8-polonia-100.png"
        import imgPortogalloB from "../assets/bandiere/bandiere-europa/icons8-portogallo-100.png"
        import imgRegnoUnitoB from "../assets/bandiere/bandiere-europa/icons8-regno-unito-100.png"
        import imgRepubblicaCecaB from "../assets/bandiere/bandiere-europa/icons8-repubblica-ceca-100.png"
        import imgRomaniaB from "../assets/bandiere/bandiere-europa/icons8-romania-100.png"
        import imgSanMarinoB from "../assets/bandiere/bandiere-europa/icons8-san-marino-100.png"
        import imgSerbiaB from "../assets/bandiere/bandiere-europa/icons8-serbia-100.png"
        import imgSlovacchiaB from "../assets/bandiere/bandiere-europa/icons8-slovacchia-100.png"
        import imgSloveniaB from "../assets/bandiere/bandiere-europa/icons8-slovenia-100.png"
        import imgSpagnaB from "../assets/bandiere/bandiere-europa/icons8-spagna-2-100.png"
        import imgSveziaB from "../assets/bandiere/bandiere-europa/icons8-svezia-100.png"
        import imgSvizzeraB from "../assets/bandiere/bandiere-europa/icons8-svizzera-100.png"
        import imgTurchiaB from "../assets/bandiere/bandiere-europa/icons8-turchia-100.png"
        import imgUcrainaB from "../assets/bandiere/bandiere-europa/icons8-ucraina-100.png"
        import imgUngheriaB from "../assets/bandiere/bandiere-europa/icons8-ungheria-100.png"

        //NORD AMERICA
        import imgAntiguaEBarbudaB from "../assets/bandiere/bandiere-nord-america/icons8-antigua-e-barbuda-100.png"
        import imgBahamasB from "../assets/bandiere/bandiere-nord-america/icons8-bahamas-100.png"
        import imgBarbadosB from "../assets/bandiere/bandiere-nord-america/icons8-barbados-100.png"
        import imgBelizeB from "../assets/bandiere/bandiere-nord-america/icons8-belize-100.png"
        import imgCanadaB from "../assets/bandiere/bandiere-nord-america/icons8-canada-100.png"
        import imgCostaRicaB from "../assets/bandiere/bandiere-nord-america/icons8-costa-rica-100.png"
        import imgCubaB from "../assets/bandiere/bandiere-nord-america/icons8-cuba-100.png"
        import imgDominicaB from "../assets/bandiere/bandiere-nord-america/icons8-dominica-100.png"
        import imgElSalvadorB from "../assets/bandiere/bandiere-nord-america/icons8-el-salvador-100.png"
        import imgGiamaicaB from "../assets/bandiere/bandiere-nord-america/icons8-giamaica-100.png"
        import imgGrenadaB from "../assets/bandiere/bandiere-nord-america/icons8-grenada-100.png"
        import imgGuatemalaB from "../assets/bandiere/bandiere-nord-america/icons8-guatemala-100.png"
        import imgHaitiB from "../assets/bandiere/bandiere-nord-america/icons8-haiti-100.png"
        import imgHondurasB from "../assets/bandiere/bandiere-nord-america/icons8-honduras-100.png"
        import imgMessicoB from "../assets/bandiere/bandiere-nord-america/icons8-messico-100.png"
        import imgNicaraguaB from "../assets/bandiere/bandiere-nord-america/icons8-nicaragua-100.png"
        import imgPanamaB from "../assets/bandiere/bandiere-nord-america/icons8-panama-100.png"
        import imgRepubblicaDominicanaB from "../assets/bandiere/bandiere-nord-america/icons8-repubblica-dominicana-100.png"
        import imgSaintKittisENevisB from "../assets/bandiere/bandiere-nord-america/icons8-saint-kittis-e-nevis-100.png"
        import imgSaintVincentEGrenadineB from "../assets/bandiere/bandiere-nord-america/icons8-saint-vincent-e-grenadine-100.png"
        import imgSantaLuciaB from "../assets/bandiere/bandiere-nord-america/icons8-santa-lucia-100.png"
        import imgTrinidadETobagoB from "../assets/bandiere/bandiere-nord-america/icons8-trinidad-e-tobago-100.png"
        import imgUsaB from "../assets/bandiere/bandiere-nord-america/icons8-usa-100.png"
        
        //OCEANIA
        import imgAustraliaB from "../assets/bandiere/bandiere-oceania/icons8-australia-100.png"
        import imgFigiB from "../assets/bandiere/bandiere-oceania/icons8-figi-100.png"
        import imgIsoleMarshallB from "../assets/bandiere/bandiere-oceania/icons8-isole-marshall-100.png"
        import imgIsoleSalomoneB from "../assets/bandiere/bandiere-oceania/icons8-isole-salomone-100.png"
        import imgKiribatiB from "../assets/bandiere/bandiere-oceania/icons8-kiribati-100.png"
        import imgMicronesiaB from "../assets/bandiere/bandiere-oceania/icons8-micronesia-100.png"
        import imgNauruB from "../assets/bandiere/bandiere-oceania/icons8-nauru-100.png"
        import imgNuovaZelandaB from "../assets/bandiere/bandiere-oceania/icons8-nuova-zelanda-100.png"
        import imgPalauB from "../assets/bandiere/bandiere-oceania/icons8-palau-100.png"
        import imgPapuaNuovaGuineaB from "../assets/bandiere/bandiere-oceania/icons8-papua-nuova-guinea-100.png"
        import imgSamoaB from "../assets/bandiere/bandiere-oceania/icons8-samoa-100.png"
        import imgTongaB from "../assets/bandiere/bandiere-oceania/icons8-tonga-100.png"
        import imgTuvaluB from "../assets/bandiere/bandiere-oceania/icons8-tuvalu-100.png"
        import imgVanuatuB from "../assets/bandiere/bandiere-oceania/icons8-vanuatu-100.png"

        //SUD AMERICA
        import imgArgentinaB from "../assets/bandiere/bandiere-sud-america/icons8-argentina-100.png"
        import imgBoliviaB from "../assets/bandiere/bandiere-sud-america/icons8-bolivia-100.png"
        import imgBrasileB from "../assets/bandiere/bandiere-sud-america/icons8-brasile-100.png"
        import imgCileB from "../assets/bandiere/bandiere-sud-america/icons8-cile-100.png"
        import imgColombiaB from "../assets/bandiere/bandiere-sud-america/icons8-colombia-100.png"
        import imgEcuadorB from "../assets/bandiere/bandiere-sud-america/icons8-ecuador-100.png"
        import imgGuyanaB from "../assets/bandiere/bandiere-sud-america/icons8-guyana-100.png"
        import imgParaguayB from "../assets/bandiere/bandiere-sud-america/icons8-paraguay-100.png"
        import imgPeruB from "../assets/bandiere/bandiere-sud-america/icons8-peru-100.png"
        import imgSurinameB from "../assets/bandiere/bandiere-sud-america/icons8-suriname-100.png"
        import imgUruguayB from "../assets/bandiere/bandiere-sud-america/icons8-uruguay-100.png"
        import imgVenezuelaB from "../assets/bandiere/bandiere-sud-america/icons8-venezuela-100.png"
    //

    //importiamo le immagini delle mappe
        //AFRICA
        import imgAlgeriaM from "../assets/stati/img-africa/algeria-mappa.png"
        import imgAngolaM from "../assets/stati/img-africa/angola-mappa.png"
        import imgBeninM from "../assets/stati/img-africa/benin-mappa.png"
        import imgBotswanaM from "../assets/stati/img-africa/botswana-mappa.png"
        import imgBurkinaFasoM from "../assets/stati/img-africa/burkina-faso-mappa.png"
        import imgBurundiM from "../assets/stati/img-africa/burundi-mappa.png"
        import imgCamerunM from "../assets/stati/img-africa/camerun-mappa.png"
        import imgCapoVerdeM from "../assets/stati/img-africa/capo-verde-mappa.png"
        import imgCiadM from "../assets/stati/img-africa/ciad-mappa.png"
        import imgComoreM from "../assets/stati/img-africa/comore-mappa.png"
        import imgCongoM from "../assets/stati/img-africa/repubblica-del-congo-mappa.png"
        import imgCostaDAvorioM from "../assets/stati/img-africa/costa-d-avorio-mappa.png"
        import imgEgittoM from "../assets/stati/img-africa/egitto-mappa.png"
        import imgEritreaM from "../assets/stati/img-africa/eritrea-mappa.png"
        import imgEtiopiaM from "../assets/stati/img-africa/etiopia-mappa.png"
        import imgGabonM from "../assets/stati/img-africa/gabon-mappa.png"
        import imgGambiaM from "../assets/stati/img-africa/gambia-mappa.png"
        import imgGhanaM from "../assets/stati/img-africa/ghana-mappa.png"
        import imgGibutiM from "../assets/stati/img-africa/gibuti-mappa.png"
        import imgGuineaM from "../assets/stati/img-africa/guinea-mappa.png"
        import imgGuineaBissauM from "../assets/stati/img-africa/guinea-bissau-mappa.png"
        import imgGuineaEquatorialeM from "../assets/stati/img-africa/guinea-equatoriale-mappa.png"
        import imgKeniaM from "../assets/stati/img-africa/kenia-mappa.png"
        import imgLesothoM from "../assets/stati/img-africa/lesotho-mappa.png"
        import imgLiberiaM from "../assets/stati/img-africa/liberia-mappa.png"
        import imgLibiaM from "../assets/stati/img-africa/libia-mappa.png"
        import imgMadagascarM from "../assets/stati/img-africa/madagascar-mappa.png"
        import imgMalawiM from "../assets/stati/img-africa/malawi-mappa.png"
        import imgMaliM from "../assets/stati/img-africa/mali-mappa.png"
        import imgMaroccoM from "../assets/stati/img-africa/marocco-mappa.png"
        import imgMauritaniaM from "../assets/stati/img-africa/mauritania-mappa.png"
        import imgMauritiusM from "../assets/stati/img-africa/mauritius-mappa.png"
        import imgMozambicoM from "../assets/stati/img-africa/mozambico-mappa.png"
        import imgNamibiaM from "../assets/stati/img-africa/namibia-mappa.png"
        import imgNigerM from "../assets/stati/img-africa/niger-mappa.png"
        import imgNigeriaM from "../assets/stati/img-africa/nigeria-mappa.png"
        import imgRepubblicaCentrafricanaM from "../assets/stati/img-africa/repubblica-centro-africana-mappa.png"
        import imgRepubblicaDemocraticaDelCongoM from "../assets/stati/img-africa/repubblica-democratica-del-congo-mappa.png"
        import imgRuandaM from "../assets/stati/img-africa/ruanda-mappa.png"
        import imgSaoTomeEPrincipeM from "../assets/stati/img-africa/sao-tome-e-principe-mappa.png"
        import imgSenegalM from "../assets/stati/img-africa/senegal-mappa.png"
        import imgSeychellesM from "../assets/stati/img-africa/seychelles-mappa.png"
        import imgSierraLeoneM from "../assets/stati/img-africa/sierra-leone-mappa.png"
        import imgSomaliaM from "../assets/stati/img-africa/somalia-mappa.png"
        import imgSudAfricaM from "../assets/stati/img-africa/sud-africa-mappa.png"
        import imgSudanM from "../assets/stati/img-africa/sudan-mappa.png"
        import imgSudanDelSudM from "../assets/stati/img-africa/sud-sudan-mappa.png"
        import imgEswatiniM from "../assets/stati/img-africa/eswatini-mappa.png"
        import imgTanzaniaM from "../assets/stati/img-africa/tanzania-mappa.png"
        import imgTogoM from "../assets/stati/img-africa/togo-mappa.png"
        import imgTunisiaM from "../assets/stati/img-africa/tunisia-mappa.png"
        import imgUgandaM from "../assets/stati/img-africa/uganda-mappa.png"
        import imgZambiaM from "../assets/stati/img-africa/zambia-mappa.png"
        import imgZimbabweM from "../assets/stati/img-africa/zimbabwe-mappa.png"

        //ASIA
        import imgAfghanistanM from "../assets/stati/img-asia/afghanistan-mappa.png"
        import imgArabiaSauditaM from "../assets/stati/img-asia/arabia-saudita-mappa.png"
        import imgArmeniaM from "../assets/stati/img-asia/armenia-mappa.png"
        import imgAzeraigianM from "../assets/stati/img-asia/azerbaigian-mappa.png"
        import imgBahreinM from "../assets/stati/img-asia/bahrein-mappa.png"
        import imgBangladeshM from "../assets/stati/img-asia/bangladesh-mappa.png"
        import imgBhutanM from "../assets/stati/img-asia/bhutan-mappa.png"
        import imgBirmaniaM from "../assets/stati/img-asia/birmania-mappa.png"
        import imgBruneiM from "../assets/stati/img-asia/brunei-mappa.png"
        import imgCambogiaM from "../assets/stati/img-asia/cambogia-mappa.png"
        import imgCinaM from "../assets/stati/img-asia/cina-mappa.png"
        import imgCoreaDelNordM from "../assets/stati/img-asia/nord-corea-mappa.png"
        import imgCoreaDelSudM from "../assets/stati/img-asia/sud-corea-mappa.png"
        import imgEmiratiArabiUnitiM from "../assets/stati/img-asia/emirati-arabi-uniti-mappa.png"
        import imgFilippineM from "../assets/stati/img-asia/filippine-mappa.png"
        import imgGeorgiaM from "../assets/stati/img-asia/georgia-mappa.png"
        import imgGiapponeM from "../assets/stati/img-asia/giappone-mappa.png"
        import imgGiordaniaM from "../assets/stati/img-asia/giordania-mappa.png"
        import imgIndiaM from "../assets/stati/img-asia/india-mappa.png"
        import imgIndonesiaM from "../assets/stati/img-asia/indonesia-mappa.png"
        import imgIranM from "../assets/stati/img-asia/iran-mappa.png"
        import imgIraqM from "../assets/stati/img-asia/iraq-mappa.png"
        import imgIsraeleM from "../assets/stati/img-asia/israele-mappa.png"
        import imgKazakistanM from "../assets/stati/img-asia/kazakistan-mappa.png"
        import imgKirghizistanM from "../assets/stati/img-asia/kirgikistan-mappa.png"
        import imgKuwaitM from "../assets/stati/img-asia/kuwait-mappa.png"
        import imgLaosM from "../assets/stati/img-asia/laos-mappa.png"
        import imgLibanoM from "../assets/stati/img-asia/libano-mappa.png"
        import imgMalaysiaM from "../assets/stati/img-asia/malasia-mappa.png"
        import imgMaldiveM from "../assets/stati/img-asia/maldive-mappa.png"
        import imgMongoliaM from "../assets/stati/img-asia/mongolia-mappa.png"
        import imgNepalM from "../assets/stati/img-asia/nepal-mappa.png"
        import imgOmanM from "../assets/stati/img-asia/oman-mappa.png"
        import imgPakistanM from "../assets/stati/img-asia/pakistan-mappa.png"
        import imgPalestinaM from "../assets/stati/img-asia/palestina-mappa.png"
        import imgQatarM from "../assets/stati/img-asia/qatar-mappa.png"
        import imgRussiaM from "../assets/stati/img-asia/russia-mappa.png"
        import imgSingaporeM from "../assets/stati/img-asia/singapore-mappa.png"
        import imgSiriaM from "../assets/stati/img-asia/siria-mappa.png"
        import imgSriLankaM from "../assets/stati/img-asia/sri-lanka-mappa.png"
        import imgTagikistanM from "../assets/stati/img-asia/tagikistan-mappa.png"
        import imgTaiwanM from "../assets/stati/img-asia/taiwan-mappa.png"
        import imgThailandiaM from "../assets/stati/img-asia/thailandia-mappa.png"
        import imgTimorEstM from "../assets/stati/img-asia/timor-est-mappa.png"
        import imgTurkmenistanM from "../assets/stati/img-asia/turkmenistan-mappa.png"
        import imgUzbekistanM from "../assets/stati/img-asia/uzbekistan-mappa.png"
        import imgVietnamM from "../assets/stati/img-asia/vietnam-mappa.png"
        import imgYemenM from "../assets/stati/img-asia/yemen-mappa.png"

        //EUROPA
        import imgAlbaniaM from "../assets/stati/img-europa/albania-mappa.png"
        import imgAndorraM from "../assets/stati/img-europa/andorra-mappa.png"
        import imgAustriaM from "../assets/stati/img-europa/austria-mappa.png"
        import imgBelarusM from "../assets/stati/img-europa/bielorussia-mappa.png"
        import imgBelgioM from "../assets/stati/img-europa/belgio-mappa.png"
        import imgBosniaEdErzegovinaM from "../assets/stati/img-europa/bosnia-mappa.png"
        import imgBulgariaM from "../assets/stati/img-europa/bulgaria-mappa.png"
        import imgCiproM from "../assets/stati/img-europa/cipro-mappa.png"
        import imgCittaDelVaticanoM from "../assets/stati/img-europa/citta-del-vaticano-mappa.png"
        import imgCroaziaM from "../assets/stati/img-europa/croazia-mappa.png"
        import imgDanimarcaM from "../assets/stati/img-europa/danimarca-mappa.png"
        import imgEstoniaM from "../assets/stati/img-europa/estonia-mappa.png"
        import imgFinlandiaM from "../assets/stati/img-europa/finlandia-mappa.png"
        import imgFranciaM from "../assets/stati/img-europa/francia-mappa.png"
        import imgGermaniaM from "../assets/stati/img-europa/germania-mappa.png"
        import imgGreciaM from "../assets/stati/img-europa/grecia-mappa.png"
        import imgIrlandaM from "../assets/stati/img-europa/irlanda-mappa.png"
        import imgIslandaM from "../assets/stati/img-europa/islanda-mappa.png"
        import imgItaliaM from "../assets/stati/img-europa/italia-mappa.png"
        import imgLettoniaM from "../assets/stati/img-europa/lettonia-mappa.png"
        import imgLiechtensteinM from "../assets/stati/img-europa/liechtenstein-mappa.png"
        import imgLituaniaM from "../assets/stati/img-europa/lituania-mappa.png"
        import imgLussemburgoM from "../assets/stati/img-europa/lussemburgo-mappa.png"
        import imgMacedoniaM from "../assets/stati/img-europa/macedonia-mappa.png"
        import imgMaltaM from "../assets/stati/img-europa/malta-mappa.png"
        import imgMoldovaM from "../assets/stati/img-europa/moldavia-mappa.png"
        import imgMonacoM from "../assets/stati/img-europa/monaco-mappa.png"
        import imgMontenegroM from "../assets/stati/img-europa/montenegro-mappa.png"
        import imgNorvegiaM from "../assets/stati/img-europa/norvegia-mappa.png"
        import imgPaesiBassiM from "../assets/stati/img-europa/paesi-bassi-mappa.png"
        import imgPoloniaM from "../assets/stati/img-europa/polonia-mappa.png"
        import imgPortogalloM from "../assets/stati/img-europa/portogallo-mappa.png"
        import imgRegnoUnitoM from "../assets/stati/img-europa/UK-mappa.png"
        import imgRepubblicaCecaM from "../assets/stati/img-europa/repubblica-ceca-mappa.png"
        import imgRomaniaM from "../assets/stati/img-europa/romania-mappa.png"
        import imgSanMarinoM from "../assets/stati/img-europa/san-marino-mappa.png"
        import imgSerbiaM from "../assets/stati/img-europa/serbia-mappa.png"
        import imgSlovacchiaM from "../assets/stati/img-europa/slovacchia-mappa.png"
        import imgSloveniaM from "../assets/stati/img-europa/slovenia-mappa.png"
        import imgSpagnaM from "../assets/stati/img-europa/spagna-mappa.png"
        import imgSveziaM from "../assets/stati/img-europa/svezia-mappa.png"
        import imgSvizzeraM from "../assets/stati/img-europa/svizzera-mappa.png"
        import imgTurchiaM from "../assets/stati/img-europa/turchia-mappa.png"
        import imgUcrainaM from "../assets/stati/img-europa/ucraina-mappa.png"
        import imgUngheriaM from "../assets/stati/img-europa/ungheria-mappa.png"
        
        //NORD AMERICA
        import imgAntiguaEBarbudaM from "../assets/stati/img-nord-america/antigua-e-barbuda-mappa.png"
        import imgBahamasM from "../assets/stati/img-nord-america/bahamas-mappa.png"
        import imgBarbadosM from "../assets/stati/img-nord-america/barbados-mappa.png"
        import imgBelizeM from "../assets/stati/img-nord-america/belize-mappa.png"
        import imgCanadaM from "../assets/stati/img-nord-america/canada-mappa.png"
        import imgCostaRicaM from "../assets/stati/img-nord-america/costa-rica-mappa.png"
        import imgCubaM from "../assets/stati/img-nord-america/cuba-mappa.png"
        import imgDominicaM from "../assets/stati/img-nord-america/dominica-mappa.png"
        import imgElSalvadorM from "../assets/stati/img-nord-america/el-salvador-mappa.png"
        import imgGiamaicaM from "../assets/stati/img-nord-america/giamaica-mappa.png"
        import imgGrenadaM from "../assets/stati/img-nord-america/grenada-mappa.png"
        import imgGuatemalaM from "../assets/stati/img-nord-america/guatemala-mappa.png"
        import imgHaitiM from "../assets/stati/img-nord-america/haiti-mappa.png"
        import imgHondurasM from "../assets/stati/img-nord-america/honduras-mappa.png"
        import imgMessicoM from "../assets/stati/img-nord-america/messico-mappa.png"
        import imgNicaraguaM from "../assets/stati/img-nord-america/nicaragua-mappa.png"
        import imgPanamaM from "../assets/stati/img-nord-america/panama-mappa.png"
        import imgRepubblicaDominicanaM from "../assets/stati/img-nord-america/repubblica-dominicana-mappa.png"
        import imgSaintKittisENevisM from "../assets/stati/img-nord-america/saint-kittis-and-nevis-mappa.png"
        import imgSaintVincentEGrenadineM from "../assets/stati/img-nord-america/saint-vincent-e-grenadine-mappa.png"
        import imgSantaLuciaM from "../assets/stati/img-nord-america/saint-lucia-mappa.png"
        import imgTrinidadETobagoM from "../assets/stati/img-nord-america/trinidad-e-tobago-mappa.png"
        import imgUsaM from "../assets/stati/img-nord-america/usa-mappa.png"
    
        //OCEANIA
        import imgAustraliaM from "../assets/stati/img-oceania/australia-mappa.png"
        import imgFigiM from "../assets/stati/img-oceania/figi-mappa.png"
        import imgIsoleMarshallM from "../assets/stati/img-oceania/isole-marshall-mappa.png"
        import imgIsoleSalomoneM from "../assets/stati/img-oceania/isole-salomone-mappa.png"
        import imgKiribatiM from "../assets/stati/img-oceania/kiribati-mappa.png"
        import imgMicronesiaM from "../assets/stati/img-oceania/micronesia-mappa.png"
        import imgNauruM from "../assets/stati/img-oceania/nauru-mappa.png"
        import imgNuovaZelandaM from "../assets/stati/img-oceania/nuova-zelanda-mappa.png"
        import imgPalauM from "../assets/stati/img-oceania/palau-mappa.png"
        import imgPapuaNuovaGuineaM from "../assets/stati/img-oceania/papua-nuova-guinea-mappa.png"
        import imgSamoaM from "../assets/stati/img-oceania/samoa-mappa.png"
        import imgTongaM from "../assets/stati/img-oceania/tonga-mappa.png"
        import imgTuvaluM from "../assets/stati/img-oceania/tuvalu-mappa.png"
        import imgVanuatuM from "../assets/stati/img-oceania/vanuatu-mappa.png"

        //SUD AMERICA
        import imgArgentinaM from "../assets/stati/img-sud-america/argentina-mappa.png"
        import imgBoliviaM from "../assets/stati/img-sud-america/bolivia-mappa.png"
        import imgBrasileM from "../assets/stati/img-sud-america/brasile-mappa.png"
        import imgCileM from "../assets/stati/img-sud-america/cile-mappa.png"
        import imgColombiaM from "../assets/stati/img-sud-america/colombia-mappa.png"
        import imgEcuadorM from "../assets/stati/img-sud-america/ecuador-mappa.png"
        import imgGuyanaM from "../assets/stati/img-sud-america/guyana-mappa.png"
        import imgParaguayM from "../assets/stati/img-sud-america/paraguay-mappa.png"
        import imgPeruM from "../assets/stati/img-sud-america/peru-mappa.png"
        import imgSurinameM from "../assets/stati/img-sud-america/suriname-mappa.png"
        import imgUruguayM from "../assets/stati/img-sud-america/uruguay-mappa.png"
        import imgVenezuelaM from "../assets/stati/img-sud-america/venezuela-mappa.png"

        //USA
        import imgAlabamaM from "../assets/stati/stati-usa/alabama-mappa.png"
        import imgAlaskaM from "../assets/stati/stati-usa/alaska-mappa.png"
        import imgArizonaM from "../assets/stati/stati-usa/arizona-mappa.png"
        import imgArkansasM from "../assets/stati/stati-usa/arkansas-mappa.png"
        import imgCaliforniaM from "../assets/stati/stati-usa/california-mappa.png"
        import imgNordCarolinaM from "../assets/stati/stati-usa/carolina-del-nord-mappa.png"
        import imgColoradoM from "../assets/stati/stati-usa/colorado-mappa.png"
        import imgConnecticutM from "../assets/stati/stati-usa/connecticut-mappa.png"
        import imgNordDakotaM from "../assets/stati/stati-usa/dakota-del-nord-mappa.png"
        import imgSudDakotaM from "../assets/stati/stati-usa/dakota-del-sud-mappa.png"
        import imgDelawareM from "../assets/stati/stati-usa/delaware-mappa.png"
        import imgDistrettoDiColumbiaM from "../assets/stati/stati-usa/distretto-di-columbia-mappa.jpg"
        import imgFloridaM from "../assets/stati/stati-usa/florida-mappa.png"
        import imgGeorgiaUsaM from "../assets/stati/stati-usa/georgia-mappa.png"
        import imgGuamM from "../assets/stati/stati-usa/guam-mappa.png"
        import imgHawaiiM from "../assets/stati/stati-usa/hawaii-mappa.png"
        import imgIdahoM from "../assets/stati/stati-usa/idaho-mappa.png"
        import imgIllinoisM from "../assets/stati/stati-usa/illinois-mappa.png"
        import imgIndianaM from "../assets/stati/stati-usa/indiana-mappa.png"
        import imgIowaM from "../assets/stati/stati-usa/iowa-mappa.png"
        import imgIsoleMarianneM from "../assets/stati/stati-usa/isole-marianne-mappa.png"
        import imgIsoleVerginiAmericaneM from "../assets/stati/stati-usa/isole-vergini-americane-mappa.png"
        import imgKansasM from "../assets/stati/stati-usa/kansas-mappa.png"
        import imgKentuckyM from "../assets/stati/stati-usa/kentucky-mappa.png"
        import imgLousianaM from "../assets/stati/stati-usa/lousiana-mappa.png"
        import imgMaineM from "../assets/stati/stati-usa/maine-mappa.png"
        import imgMarylandM from "../assets/stati/stati-usa/maryland-mappa.png"
        import imgMassachussetsM from "../assets/stati/stati-usa/massachussets-mappa.png"
        import imgMichiganM from "../assets/stati/stati-usa/michigan-mappa.png"
        import imgMinnesotaM from "../assets/stati/stati-usa/minnesota-mappa.png"
        import imgMississippiM from "../assets/stati/stati-usa/mississippi-mappa.png"
        import imgMissouriM from "../assets/stati/stati-usa/missouri-mappa.png"
        import imgMontanaM from "../assets/stati/stati-usa/montana-mappa.png"
        import imgNebraskaM from "../assets/stati/stati-usa/nebraska-mappa.png"
        import imgNevadaM from "../assets/stati/stati-usa/nevada-mappa.png"
        import imgNewHampshireM from "../assets/stati/stati-usa/new-hampshire-mappa.png"
        import imgNewJerseyM from "../assets/stati/stati-usa/new-jersey-mappa.png"
        import imgNewMessicoM from "../assets/stati/stati-usa/new-messico-mappa.png"
        import imgNewYorkM from "../assets/stati/stati-usa/new-york-mappa.png"
        import imgOhioM from "../assets/stati/stati-usa/ohio-mappa.png"
        import imgOklahomaM from "../assets/stati/stati-usa/oklahoma-mappa.png"
        import imgOregonM from "../assets/stati/stati-usa/oregon-mappa.png"
        import imgPennsylvaniaM from "../assets/stati/stati-usa/pennsylvania-mappa.png"
        import imgPortoRicoM from "../assets/stati/stati-usa/porto-rico-mappa.png"
        import imgRhodeIslandM from "../assets/stati/stati-usa/rhode-island-mappa.png"
        import imgSamoaAmericaneM from "../assets/stati/stati-usa/samoa-americane-mappa.png"
        import imgSudCarolinaM from "../assets/stati/stati-usa/sud-carolina-mappa.png"
        import imgTennesseeM from "../assets/stati/stati-usa/tennessee-mappa.png"
        import imgTexasM from "../assets/stati/stati-usa/texas-mappa.png"
        import imgUtahM from "../assets/stati/stati-usa/utah-mappa.png"
        import imgVermontM from "../assets/stati/stati-usa/vermont-mappa.png"
        import imgVirginiaM from "../assets/stati/stati-usa/virginia-mappa.png"
        import imgVirginiaOccidentaleM from "../assets/stati/stati-usa/virginia-occidentale-mappa.png"
        import imgWashingtonM from "../assets/stati/stati-usa/washington-mappa.png"
        import imgWisconsinM from "../assets/stati/stati-usa/wisconsin-mappa.png"
        import imgWyomingM from "../assets/stati/stati-usa/wyoming-mappa.png"
    //

    export default{
        name: 'cardStudioEN',
        props: ['continente'],

        
        components: {
            //importo swiper
            Swiper,
            SwiperSlide,
           
        },
        setup() {
            return {
                modules: [EffectCards],
            };
        },
    

        data(){
            return{
                //IMPORTIAMO LE IMMAGINI DELLE BANDIERE
                BandiereEuropa: [
                    {id:1, imgBandiera:imgAlbaniaB},
                    {id:2, imgBandiera:imgAndorraB},
                    {id:3, imgBandiera:imgAustriaB},
                    {id:4, imgBandiera:imgBelgioB},
                    {id:5, imgBandiera:imgBelarusB},
                    {id:6, imgBandiera:imgBosniaEdErzegovinaB},
                    {id:7, imgBandiera:imgBulgariaB},
                    {id:8, imgBandiera:imgCiproB},
                    {id:9, imgBandiera:imgCittaDelVaticanoB},
                    {id:10, imgBandiera:imgCroaziaB},
                    {id:11, imgBandiera:imgDanimarcaB},
                    {id:12, imgBandiera:imgEstoniaB},
                    {id:13, imgBandiera:imgFinlandiaB},
                    {id:14, imgBandiera:imgFranciaB},
                    {id:15, imgBandiera:imgGermaniaB},
                    {id:16, imgBandiera:imgGreciaB},
                    {id:17, imgBandiera:imgIrlandaB},
                    {id:18, imgBandiera:imgIslandaB},
                    {id:19, imgBandiera:imgItaliaB},
                    {id:20, imgBandiera:imgLettoniaB},
                    {id:21, imgBandiera:imgLiechtensteinB},
                    {id:22, imgBandiera:imgLituaniaB},
                    {id:23, imgBandiera:imgLussemburgoB},
                    {id:24, imgBandiera:imgMacedoniaB},
                    {id:25, imgBandiera:imgMaltaB},
                    {id:26, imgBandiera:imgMoldovaB},
                    {id:27, imgBandiera:imgMonacoB},
                    {id:28, imgBandiera:imgMontenegroB},
                    {id:29, imgBandiera:imgNorvegiaB},
                    {id:30, imgBandiera:imgPaesiBassiB},
                    {id:31, imgBandiera:imgPoloniaB},
                    {id:32, imgBandiera:imgPortogalloB},
                    {id:33, imgBandiera:imgRegnoUnitoB},
                    {id:34, imgBandiera:imgRepubblicaCecaB},
                    {id:35, imgBandiera:imgRomaniaB},
                    {id:36, imgBandiera:imgSanMarinoB},
                    {id:37, imgBandiera:imgSerbiaB},
                    {id:38, imgBandiera:imgSlovacchiaB},
                    {id:39, imgBandiera:imgSloveniaB},
                    {id:40, imgBandiera:imgSpagnaB},
                    {id:41, imgBandiera:imgSveziaB},
                    {id:42, imgBandiera:imgSvizzeraB},
                    {id:43, imgBandiera:imgTurchiaB},
                    {id:44, imgBandiera:imgUcrainaB},
                    {id:45, imgBandiera:imgUngheriaB},
                ],
                BandiereAsia: [
                    {id:1, imgBandiera:imgAfghanistanB},
                    {id:2, imgBandiera:imgArabiaSauditaB},
                    {id:3, imgBandiera:imgArmeniaB},
                    {id:4, imgBandiera:imgAzeraigianB},
                    {id:5, imgBandiera:imgBahreinB},
                    {id:6, imgBandiera:imgBangladeshB},
                    {id:7, imgBandiera:imgBirmaniaB},
                    {id:8, imgBandiera:imgBhutanB},
                    {id:9, imgBandiera:imgBruneiB},
                    {id:10, imgBandiera:imgCambogiaB},
                    {id:11, imgBandiera:imgCinaB},
                    {id:12, imgBandiera:imgCoreaDelNordB},
                    {id:13, imgBandiera:imgCoreaDelSudB},
                    {id:14, imgBandiera:imgEmiratiArabiUnitiB},
                    {id:15, imgBandiera:imgFilippineB},
                    {id:16, imgBandiera:imgGeorgiaB},
                    {id:17, imgBandiera:imgGiapponeB},
                    {id:18, imgBandiera:imgGiordaniaB},
                    {id:19, imgBandiera:imgIndiaB},
                    {id:20, imgBandiera:imgIndonesiaB},
                    {id:21, imgBandiera:imgIranB},
                    {id:22, imgBandiera:imgIraqB},
                    {id:23, imgBandiera:imgIsraeleB},
                    {id:24, imgBandiera:imgKazakistanB},
                    {id:25, imgBandiera:imgKirghizistanB},
                    {id:26, imgBandiera:imgKuwaitB},
                    {id:27, imgBandiera:imgLaosB},
                    {id:28, imgBandiera:imgLibanoB},
                    {id:29, imgBandiera:imgMaldiveB},
                    {id:30, imgBandiera:imgMalaysiaB},
                    {id:31, imgBandiera:imgMongoliaB},
                    {id:32, imgBandiera:imgNepalB},
                    {id:33, imgBandiera:imgOmanB},
                    {id:34, imgBandiera:imgPakistanB},
                    {id:35, imgBandiera:imgPalestinaB},
                    {id:36, imgBandiera:imgQatarB},
                    {id:37, imgBandiera:imgRussiaB},
                    {id:38, imgBandiera:imgSingaporeB},
                    {id:39, imgBandiera:imgSiriaB},
                    {id:40, imgBandiera:imgSriLankaB},
                    {id:41, imgBandiera:imgTagikistanB},
                    {id:42, imgBandiera:imgTaiwanB},
                    {id:43, imgBandiera:imgThailandiaB},
                    {id:44, imgBandiera:imgTimorEstB},
                    {id:45, imgBandiera:imgTurkmenistanB},
                    {id:46, imgBandiera:imgUzbekistanB},
                    {id:47, imgBandiera:imgVietnamB},
                    {id:48, imgBandiera:imgYemenB}
                ],
                BandiereAfrica: [
                    {id:1, imgBandiera:imgAlgeriaB},
                    {id:2, imgBandiera:imgAngolaB},
                    {id:3, imgBandiera:imgBeninB},
                    {id:4, imgBandiera:imgBotswanaB},
                    {id:5, imgBandiera:imgBurkinaFasoB},
                    {id:6, imgBandiera:imgBurundiB},
                    {id:7, imgBandiera:imgCamerunB},
                    {id:8, imgBandiera:imgCapoVerdeB},
                    {id:9, imgBandiera:imgRepubblicaCentrafricanaB},
                    {id:10, imgBandiera:imgCiadB},
                    {id:11, imgBandiera:imgComoreB},
                    {id:12, imgBandiera:imgCostaDAvorioB},
                    {id:13, imgBandiera:imgRepubblicaDemocraticaDelCongoB},
                    {id:14, imgBandiera:imgCongoB},
                    {id:15, imgBandiera:imgEgittoB},
                    {id:16, imgBandiera:imgEritreaB},
                    {id:17, imgBandiera:imgEtiopiaB},
                    {id:18, imgBandiera:imgGabonB},
                    {id:19, imgBandiera:imgGambiaB},
                    {id:20, imgBandiera:imgGhanaB},
                    {id:21, imgBandiera:imgGibutiB},
                    {id:22, imgBandiera:imgGuineaB},
                    {id:23, imgBandiera:imgGuineaBissauB},
                    {id:24, imgBandiera:imgGuineaEquatorialeB},
                    {id:25, imgBandiera:imgKeniaB},
                    {id:26, imgBandiera:imgLesothoB},
                    {id:27, imgBandiera:imgLiberiaB},
                    {id:28, imgBandiera:imgLibiaB},
                    {id:29, imgBandiera:imgMadagascarB},
                    {id:30, imgBandiera:imgMalawiB},
                    {id:31, imgBandiera:imgMaliB},
                    {id:32, imgBandiera:imgMauritaniaB},
                    {id:33, imgBandiera:imgMauritiusB},
                    {id:34, imgBandiera:imgMaroccoB},
                    {id:35, imgBandiera:imgMozambicoB},
                    {id:36, imgBandiera:imgNamibiaB},
                    {id:37, imgBandiera:imgNigerB},
                    {id:38, imgBandiera:imgNigeriaB},
                    {id:39, imgBandiera:imgRuandaB},
                    {id:40, imgBandiera:imgSaoTomeEPrincipeB},
                    {id:41, imgBandiera:imgSenegalB},
                    {id:42, imgBandiera:imgSeychellesB},
                    {id:43, imgBandiera:imgSierraLeoneB},
                    {id:44, imgBandiera:imgSomaliaB},
                    {id:45, imgBandiera:imgSudAfricaB},
                    {id:46, imgBandiera:imgSudanB},
                    {id:47, imgBandiera:imgSudanDelSudB},
                    {id:48, imgBandiera:imgSwazilandB},
                    {id:49, imgBandiera:imgTanzaniaB},
                    {id:50, imgBandiera:imgTogoB},
                    {id:51, imgBandiera:imgTunisiaB},
                    {id:52, imgBandiera:imgUgandaB},
                    {id:53, imgBandiera:imgZambiaB},
                    {id:54, imgBandiera:imgZimbabweB}
                ],
                BandiereNordAmerica: [
                    {id:1, imgBandiera:imgAntiguaEBarbudaB},
                    {id:2, imgBandiera:imgBahamasB},
                    {id:3, imgBandiera:imgBarbadosB},
                    {id:4, imgBandiera:imgBelizeB},
                    {id:5, imgBandiera:imgCanadaB},
                    {id:6, imgBandiera:imgCostaRicaB},
                    {id:7, imgBandiera:imgCubaB},
                    {id:8, imgBandiera:imgDominicaB},
                    {id:9, imgBandiera:imgElSalvadorB},
                    {id:10, imgBandiera:imgGiamaicaB},
                    {id:11, imgBandiera:imgGrenadaB},
                    {id:12, imgBandiera:imgGuatemalaB},
                    {id:13, imgBandiera:imgHaitiB},
                    {id:14, imgBandiera:imgHondurasB},
                    {id:15, imgBandiera:imgMessicoB},
                    {id:16, imgBandiera:imgNicaraguaB},
                    {id:17, imgBandiera:imgPanamaB},
                    {id:18, imgBandiera:imgRepubblicaDominicanaB},
                    {id:19, imgBandiera:imgSaintKittisENevisB},
                    {id:20, imgBandiera:imgSaintVincentEGrenadineB},
                    {id:21, imgBandiera:imgSantaLuciaB},
                    {id:22, imgBandiera:imgTrinidadETobagoB},
                    {id:23, imgBandiera:imgUsaB}
                ],
                BandiereOceania: [
                    {id:1, imgBandiera:imgAustraliaB},
                    {id:2, imgBandiera:imgNuovaZelandaB},
                    {id:3, imgBandiera:imgPapuaNuovaGuineaB},
                    {id:4, imgBandiera:imgFigiB},
                    {id:5, imgBandiera:imgIsoleSalomoneB},
                    {id:6, imgBandiera:imgVanuatuB},
                    {id:7, imgBandiera:imgMicronesiaB},
                    {id:8, imgBandiera:imgKiribatiB},
                    {id:9, imgBandiera:imgIsoleMarshallB},
                    {id:10, imgBandiera:imgPalauB},
                    {id:11, imgBandiera:imgNauruB},
                    {id:12, imgBandiera:imgSamoaB},
                    {id:13, imgBandiera:imgTongaB},
                    {id:14, imgBandiera:imgTuvaluB}
                ],
                BandiereSudAmerica: [
                    {id:1, imgBandiera:imgArgentinaB},
                    {id:2, imgBandiera:imgBoliviaB},
                    {id:3, imgBandiera:imgBrasileB},
                    {id:4, imgBandiera:imgCileB},
                    {id:5, imgBandiera:imgColombiaB},
                    {id:6, imgBandiera:imgEcuadorB},
                    {id:7, imgBandiera:imgGuyanaB},
                    {id:8, imgBandiera:imgParaguayB},
                    {id:9, img6Bandiera:imgPeruB},
                    {id:10, imgBandiera:imgSurinameB},
                    {id:11, imgBandiera:imgUruguayB},
                    {id:12, imgBandiera:imgVenezuelaB}
                ],

                //IMPORTIAMO LE IMMAGINI DELLE MAPPE
                mappeEuropa: [
                    {id:1, imgMappa:imgAlbaniaM},
                    {id:2, imgMappa:imgAndorraM},
                    {id:3, imgMappa:imgAustriaM},
                    {id:4, imgMappa:imgBelgioM},
                    {id:5, imgMappa:imgBelarusM},
                    {id:6, imgMappa:imgBosniaEdErzegovinaM},
                    {id:7, imgMappa:imgBulgariaM},
                    {id:8, imgMappa:imgCiproM},
                    {id:9, imgMappa:imgCittaDelVaticanoM},
                    {id:10, imgMappa:imgCroaziaM},
                    {id:11, imgMappa:imgDanimarcaM},
                    {id:12, imgMappa:imgEstoniaM},
                    {id:13, imgMappa:imgFinlandiaM},
                    {id:14, imgMappa:imgFranciaM},
                    {id:15, imgMappa:imgGermaniaM},
                    {id:16, imgMappa:imgGreciaM},
                    {id:17, imgMappa:imgIrlandaM},
                    {id:18, imgMappa:imgIslandaM},
                    {id:19, imgMappa:imgItaliaM},
                    {id:20, imgMappa:imgLettoniaM},
                    {id:21, imgMappa:imgLiechtensteinM},
                    {id:22, imgMappa:imgLituaniaM},
                    {id:23, imgMappa:imgLussemburgoM},
                    {id:24, imgMappa:imgMacedoniaM},
                    {id:25, imgMappa:imgMaltaM},
                    {id:26, imgMappa:imgMoldovaM},
                    {id:27, imgMappa:imgMonacoM},
                    {id:28, imgMappa:imgMontenegroM},
                    {id:29, imgMappa:imgNorvegiaM},
                    {id:30, imgMappa:imgPaesiBassiM},
                    {id:31, imgMappa:imgPoloniaM},
                    {id:32, imgMappa:imgPortogalloM},
                    {id:33, imgMappa:imgRegnoUnitoM},
                    {id:34, imgMappa:imgRepubblicaCecaM},
                    {id:35, imgMappa:imgRomaniaM},
                    {id:36, imgMappa:imgSanMarinoM},
                    {id:37, imgMappa:imgSerbiaM},
                    {id:38, imgMappa:imgSlovacchiaM},
                    {id:39, imgMappa:imgSloveniaM},
                    {id:40, imgMappa:imgSpagnaM},
                    {id:41, imgMappa:imgSveziaM},
                    {id:42, imgMappa:imgSvizzeraM},
                    {id:43, imgMappa:imgTurchiaM},
                    {id:44, imgMappa:imgUcrainaM},
                    {id:45, imgMappa:imgUngheriaM},
                ],
                mappeAsia: [
                    {id:1, imgMappa:imgAfghanistanM},
                    {id:2, imgMappa:imgArabiaSauditaM},
                    {id:3, imgMappa:imgArmeniaM},
                    {id:4, imgMappa:imgAzeraigianM},
                    {id:5, imgMappa:imgBahreinM},
                    {id:6, imgMappa:imgBangladeshM},
                    {id:7, imgMappa:imgBirmaniaM},
                    {id:8, imgMappa:imgBhutanM},
                    {id:9, imgMappa:imgBruneiM},
                    {id:10, imgMappa:imgCambogiaM},
                    {id:11, imgMappa:imgCinaM},
                    {id:12, imgMappa:imgCoreaDelNordM},
                    {id:13, imgMappa:imgCoreaDelSudM},
                    {id:14, imgMappa:imgEmiratiArabiUnitiM},
                    {id:15, imgMappa:imgFilippineM},
                    {id:16, imgMappa:imgGeorgiaM},
                    {id:17, imgMappa:imgGiapponeM},
                    {id:18, imgMappa:imgGiordaniaM},
                    {id:19, imgMappa:imgIndiaM},
                    {id:20, imgMappa:imgIndonesiaM},
                    {id:21, imgMappa:imgIranM},
                    {id:22, imgMappa:imgIraqM},
                    {id:23, imgMappa:imgIsraeleM},
                    {id:24, imgMappa:imgKazakistanM},
                    {id:25, imgMappa:imgKirghizistanM},
                    {id:26, imgMappa:imgKuwaitM},
                    {id:27, imgMappa:imgLaosM},
                    {id:28, imgMappa:imgLibanoM},
                    {id:29, imgMappa:imgMaldiveM},
                    {id:30, imgMappa:imgMalaysiaM},
                    {id:31, imgMappa:imgMongoliaM},
                    {id:32, imgMappa:imgNepalM},
                    {id:33, imgMappa:imgOmanM},
                    {id:34, imgMappa:imgPakistanM},
                    {id:35, imgMappa:imgPalestinaM},
                    {id:36, imgMappa:imgQatarM},
                    {id:37, imgMappa:imgRussiaM},
                    {id:38, imgMappa:imgSingaporeM},
                    {id:39, imgMappa:imgSiriaM},
                    {id:40, imgMappa:imgSriLankaM},
                    {id:41, imgMappa:imgTagikistanM},
                    {id:42, imgMappa:imgTaiwanM},
                    {id:43, imgMappa:imgThailandiaM},
                    {id:44, imgMappa:imgTimorEstM},
                    {id:45, imgMappa:imgTurkmenistanM},
                    {id:46, imgMappa:imgUzbekistanM},
                    {id:47, imgMappa:imgVietnamM},
                    {id:48, imgMappa:imgYemenM},
                ],
                mappeAfrica: [
                    {id:1, imgMappa:imgAlgeriaM},
                    {id:2, imgMappa:imgAngolaM},
                    {id:3, imgMappa:imgBeninM},
                    {id:4, imgMappa:imgBotswanaM},
                    {id:5, imgMappa:imgBurkinaFasoM},
                    {id:6, imgMappa:imgBurundiM},
                    {id:7, imgMappa:imgCamerunM},
                    {id:8, imgMappa:imgCapoVerdeM},
                    {id:9, imgMappa:imgRepubblicaCentrafricanaM},
                    {id:10, imgMappa:imgCiadM},
                    {id:11, imgMappa:imgComoreM},
                    {id:12, imgMappa:imgCostaDAvorioM},
                    {id:13, imgMappa:imgRepubblicaDemocraticaDelCongoM},
                    {id:14, imgMappa:imgCongoM},
                    {id:15, imgMappa:imgEgittoM},
                    {id:16, imgMappa:imgEritreaM},
                    {id:17, imgMappa:imgEtiopiaM},
                    {id:18, imgMappa:imgGabonM},
                    {id:19, imgMappa:imgGambiaM},
                    {id:20, imgMappa:imgGhanaM},
                    {id:21, imgMappa:imgGibutiM},
                    {id:22, imgMappa:imgGuineaM},
                    {id:23, imgMappa:imgGuineaBissauM},
                    {id:24, imgMappa:imgGuineaEquatorialeM},
                    {id:25, imgMappa:imgKeniaM},
                    {id:26, imgMappa:imgLesothoM},
                    {id:27, imgMappa:imgLiberiaM},
                    {id:28, imgMappa:imgLibiaM},
                    {id:29, imgMappa:imgMadagascarM},
                    {id:30, imgMappa:imgMalawiM},
                    {id:31, imgMappa:imgMaliM},
                    {id:32, imgMappa:imgMauritaniaM},
                    {id:33, imgMappa:imgMauritiusM},
                    {id:34, imgMappa:imgMaroccoM},
                    {id:35, imgMappa:imgMozambicoM},
                    {id:36, imgMappa:imgNamibiaM},
                    {id:37, imgMappa:imgNigerM},
                    {id:38, imgMappa:imgNigeriaM},
                    {id:39, imgMappa:imgRuandaM},
                    {id:40, imgMappa:imgSaoTomeEPrincipeM},
                    {id:41, imgMappa:imgSenegalM},
                    {id:42, imgMappa:imgSeychellesM},
                    {id:43, imgMappa:imgSierraLeoneM},
                    {id:44, imgMappa:imgSomaliaM},
                    {id:45, imgMappa:imgSudAfricaM},
                    {id:46, imgMappa:imgSudanM},
                    {id:47, imgMappa:imgSudanDelSudM},
                    {id:48, imgMappa:imgEswatiniM},
                    {id:49, imgMappa:imgTanzaniaM},
                    {id:50, imgMappa:imgTogoM},
                    {id:51, imgMappa:imgTunisiaM},
                    {id:52, imgMappa:imgUgandaM},
                    {id:53, imgMappa:imgZambiaM},
                    {id:54, imgMappa:imgZimbabweM},
                ],
                mappeNordAmerica: [
                    {id:1, imgMappa:imgAntiguaEBarbudaM},
                    {id:2, imgMappa:imgBahamasM},
                    {id:3, imgMappa:imgBarbadosM},
                    {id:4, imgMappa:imgBelizeM},
                    {id:5, imgMappa:imgCanadaM},
                    {id:6, imgMappa:imgCostaRicaM},
                    {id:7, imgMappa:imgCubaM},
                    {id:8, imgMappa:imgDominicaM},
                    {id:9, imgMappa:imgElSalvadorM},
                    {id:10, imgMappa:imgGiamaicaM},
                    {id:11, imgMappa:imgGrenadaM},
                    {id:12, imgMappa:imgGuatemalaM},
                    {id:13, imgMappa:imgHaitiM},
                    {id:14, imgMappa:imgHondurasM},
                    {id:15, imgMappa:imgMessicoM},
                    {id:16, imgMappa:imgNicaraguaM},
                    {id:17, imgMappa:imgPanamaM},
                    {id:18, imgMappa:imgRepubblicaDominicanaM},
                    {id:19, imgMappa:imgSaintKittisENevisM},
                    {id:20, imgMappa:imgSaintVincentEGrenadineM},
                    {id:21, imgMappa:imgSantaLuciaM},
                    {id:22, imgMappa:imgTrinidadETobagoM},
                    {id:23, imgMappa:imgUsaM}
                ],
                mappeOceania: [
                    {id:1, imgMappa:imgAustraliaM},
                    {id:2, imgMappa:imgNuovaZelandaM},
                    {id:3, imgMappa:imgPapuaNuovaGuineaM},
                    {id:4, imgMappa:imgFigiM},
                    {id:5, imgMappa:imgIsoleSalomoneM},
                    {id:6, imgMappa:imgVanuatuM},
                    {id:7, imgMappa:imgMicronesiaM},
                    {id:8, imgMappa:imgKiribatiM},
                    {id:9, imgMappa:imgIsoleMarshallM},
                    {id:10, imgMappa:imgPalauM},
                    {id:11, imgMappa:imgNauruM},
                    {id:12, imgMappa:imgSamoaM},
                    {id:13, imgMappa:imgTongaM},
                    {id:14, imgMappa:imgTuvaluM}
                ],
                mappeSudAmerica: [
                    {id:1, imgMappa:imgArgentinaM},
                    {id:2, imgMappa:imgBoliviaM},
                    {id:3, imgMappa:imgBrasileM},
                    {id:4, imgMappa:imgCileM},
                    {id:5, imgMappa:imgColombiaM},
                    {id:6, imgMappa:imgEcuadorM},
                    {id:7, imgMappa:imgGuyanaM},
                    {id:8, imgMappa:imgParaguayM},
                    {id:9, imgMappa:imgPeruM},
                    {id:10, imgMappa:imgSurinameM},
                    {id:11, imgMappa:imgUruguayM},
                    {id:12, imgMappa:imgVenezuelaM}
                ],
                mappeUSA: [
                    {id:1, imgMappa:imgCaliforniaM},
                    {id:2, imgMappa:imgTexasM},
                    {id:3, imgMappa:imgFloridaM},
                    {id:4, imgMappa:imgNewYorkM},
                    {id:5, imgMappa:imgPennsylvaniaM},
                    {id:6, imgMappa:imgIllinoisM},
                    {id:7, imgMappa:imgOhioM},
                    {id:8, imgMappa:imgGeorgiaUsaM},
                    {id:9, imgMappa:imgNordCarolinaM},
                    {id:10, imgMappa:imgMichiganM},
                    {id:11, imgMappa:imgNewJerseyM},
                    {id:12, imgMappa:imgVirginiaM},
                    {id:13, imgMappa:imgWashingtonM},
                    {id:14, imgMappa:imgArizonaM},
                    {id:15, imgMappa:imgMassachussetsM},
                    {id:16, imgMappa:imgTennesseeM},
                    {id:17, imgMappa:imgIndiaM},
                    {id:18, imgMappa:imgMarylandM},
                    {id:19, imgMappa:imgMissouriM},
                    {id:20, imgMappa:imgWisconsinM},
                    {id:21, imgMappa:imgColoradoM},
                    {id:22, imgMappa:imgMinnesotaM},
                    {id:23, imgMappa:imgSudCarolinaM},
                    {id:24, imgMappa:imgAlabamaM},
                    {id:25, imgMappa:imgLousianaM},
                    {id:26, imgMappa:imgKentuckyM},
                    {id:27, imgMappa:imgOregonM},
                    {id:28, imgMappa:imgOklahomaM},
                    {id:29, imgMappa:imgConnecticutM},
                    {id:30, imgMappa:imgUtahM},
                    {id:31, imgMappa:imgIowaM},
                    {id:32, imgMappa:imgNevadaM},
                    {id:33, imgMappa:imgArkansasM},
                    {id:34, imgMappa:imgMississippiM},
                    {id:35, imgMappa:imgKansasM},
                    {id:36, imgMappa:imgNewMessicoM},
                    {id:37, imgMappa:imgNebraskaM},
                    {id:38, imgMappa:imgIdahoM},
                    {id:39, imgMappa:imgVirginiaOccidentaleM},
                    {id:40, imgMappa:imgHawaiiM},
                    {id:41, imgMappa:imgNewHampshireM},
                    {id:42, imgMappa:imgMaineM},
                    {id:43, imgMappa:imgRhodeIslandM},
                    {id:44, imgMappa:imgMontanaM},
                    {id:45, imgMappa:imgDelawareM},
                    {id:46, imgMappa:imgSudDakotaM},
                    {id:47, imgMappa:imgNordDakotaM},
                    {id:48, imgMappa:imgAlaskaM},
                    {id:49, imgMappa:imgVermontM},
                    {id:50, imgMappa:imgWyomingM},
                    {id:51, imgMappa:imgDistrettoDiColumbiaM},
                    {id:52, imgMappa:imgPortoRicoM},
                    {id:53, imgMappa:imgGuamM},
                    {id:54, imgMappa:imgIsoleVerginiAmericaneM},
                    {id:55, imgMappa:imgSamoaAmericaneM},
                    {id:56, imgMappa:imgIsoleMarianneM},
                ],    
            }
        }
    }

</script>

<style>

    /* body e immagine di sfondo */
    body {
    margin: 0;
    padding: 0;
    height: max-content;
    width: 100%;
    }
    .img-sfondo-stdio{
    background-image: url("../assets/map_world_sfondo.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    min-width: max-content;
    height: 100%;
    padding: 0;
    margin: 0;
    }
    .tutto{
        box-sizing: content-box;
        min-width: max-content;
    }

    /* TITOLO e testo sottotitolo*/
    .margin-title-s{
    padding-top: 28%;
    padding-bottom: 5%;
    }
    .studio-title{
        color: black;
        background-color: rgba(189, 220, 249, 0.69);
        backdrop-filter: blur(10px);
        border: 5px solid black;
        border-radius: 100px;
        font-weight: bold;
        font-size: 20px;
        
        filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
        padding: 10px 40px 10px 40px;  
    }
    .margin-title-sub{
    padding-bottom: 5%;
    }
    .studio-subtitle{
        color: black;
        background-color: rgba(189, 220, 249, 0.69);
        backdrop-filter: blur(10px);
        border: 5px solid black;
        border-radius: 100px;
        font-weight: bold;
        font-size: 20px;
        
        filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
        padding: 10px 40px 10px 40px;  
    }
    .testo-a-capo{
        width: 30vh;
        font-size: 15px;
        word-wrap:break-word;
    }

    /* CARDS */
    .swiper {  
        width: 170px;
        height: 450px;
        padding: 3% 10% ;
        box-sizing: content-box;
    }
    .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    /* font-size: 22px; */
    color: rgba(189, 220, 249, 0.69);
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(20px);
    border: solid 5px rgba(189, 220, 249, 0.69);
    /* box-sizing: content-box; */
    padding-top: 5%;
    }

    /* titolo prima card */
    .titolo-continente-custom{
        background-color: transparent;
        backdrop-filter: blur(20px);
        border: 5px solid  rgba(189, 220, 249, 0.69);
        border-radius: 100px;
        width: max-content;
        padding: 20% 30%;
        margin-bottom: 5%;
    }
    .size-title{
        font-size: 20px;
        font-weight: bold;
    }
    .size-title-america{
        font-size: 15px;
        font-weight: bold;
    }

    /* parte scritta */
    .sottolineamento-stato{
        border-bottom: 2px solid rgba(189, 220, 249, 0.69);
        margin-bottom: 20px;
        font-size: 22px;
    }
    .size-capitale{
        font-size: 18px;
        margin: 0 5px;
    }

    /* IMMAGINI */
    .centramento-immagini-studio{
        margin-top: 20px;
    }
    .img-bandiera-size{  
        width: 150px;
    }
    .img-mappa-border{
        border: 8px solid white;
        border-radius: 20px;
        width: 130px;
        max-height: 160px;
    }


    /* media query */
    @media (min-width: 280px) {  
        .swiper {  

            padding: 3% 20% ;
        }
    }
    @media (min-width: 300px) { 
        
        .studio-title{
            font-size: 30px;
            padding: 10px 50px 10px 50px;
        }
        .margin-title-s{
            padding-top: 23%;
        }   
    }
    @media (min-width: 334px) { 
        .swiper {  
            padding: 3% 25% ;
        }
        .testo-a-capo{
            width: 40vh;
        } 
    }
    @media (min-width: 390px) {  
    .studio-title{
        font-size: 35px;
        padding: 10px 60px 10px 60px;
    }
    
    }
    @media (min-width: 418px) { 
        .margin-title-s{
            padding-top: 20%;
        }
        .studio-title{
            font-size: 30px;
            padding: 10px 70px 10px 70px;
        }
        .titolo-continente-custom{
            padding: 15% 15%; 
        }
        .size-title{
            font-size: 23px;
        }
        .size-title-america{
            font-size: 18px;
        }
        .swiper {  
        
            padding: 3% 30% ;
        }
    
    }
    @media (min-width: 470px) { 
        .testo-a-capo{
        width: 50vh;
        font-size: 20px;
        }
    .margin-title-s{
        padding-top: 18%;
    }
    
    }
    @media (min-width: 500px) { 
        
        .size-title{
            font-size: 30px;
        }
        .size-title-america{
            font-size: 22px;
        }
        .swiper {  
            width: 200px;
            height: 450px;
            padding: 3% 30% ;
        }
        .sottolineamento-stato{
            font-size: 25px;
        }
        .size-capitale{
            font-size: 20px;
        }
        .img-bandiera-size{  
            width: 170px;   
        }
        .img-mappa-border{
            width: 150px;
            max-height: 160px;
        }
    }
    @media (min-width: 570px) { 
    .margin-title-s{
        padding-top: 15%;
    }
    .swiper {  
            width: 220px;
            height: 450px;
            padding: 3% 30% ;
        }
    }
    @media (min-width: 620px) { 
        .centramento-immagini-studio{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0px;
        }
        .testo-a-capo{
            width: 50vh;
        }
        .studio-title{
            font-size: 55px;
            padding: 10px 80px 10px 80px;
        }
        .titolo-continente-custom{
            padding: 20% 20%; 
        }
        .size-title{
            font-size: 35px;
        }
        .size-title-america{
            font-size: 30px;
        }
        .swiper {  
            width: 300px;
            height: 300px;
            padding: 3% 26% ;
        }
        .img-bandiera-size{
            margin-top: 7%;
            margin-bottom: 1%;
            width: 150px;
        }
        .img-mappa-border{
            width: 115px;
            margin-top: 1%;
            margin-bottom: 1%;
        }
    }
    @media (min-width: 794px){
        
        .studio-title{
            font-size: 45px;
            padding: 10px 90px 10px 90px;
        }
        .margin-title-s{
            padding-top: 15%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 5%;
        }
        .swiper {  
            padding: 3% 25% ;
            width: 350px;
            height: 320px;
        }
        .img-bandiera-size{
            margin-top: 7%;
            margin-bottom: 1%;
            width: 180px;
        }
        .img-mappa-border{
            width: 130px;
            margin-top: 1%;
            margin-bottom: 1%;
        }
        
    }
    @media (min-width: 992px) { 
        .margin-title-s{
            padding-top: 13%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 5%;
        }
        .studio-title{
            padding: 10px 100px 10px 100px;
        }
    
        .titolo-continente-custom{
            padding: 20% 30%; 
        }
        .size-title{
            font-size: 40px;
        }
        .size-title-america{
            font-size: 35px;
        }
        .swiper {  
            width: 400px;
            height: 350px;
        }
        .img-bandiera-size{
            
            width: 200px;
        }
        .img-mappa-border{
            width: 155px;
        }
    }
    @media (min-width: 1247px) { 
        .studio-title{
            font-size: 50px;
        }
        .margin-title-s{
            padding-top: 8%;
            padding-bottom: 3%;
        }
        .margin-title-sub{
            padding-bottom: 3%;
        }
        .swiper {  
            width: 500px;
            height: 430px;
        }
        .img-bandiera-size{
            
            width: 250px;
        }
        .img-mappa-border{
            width: 200px;
        }
    }



</style>