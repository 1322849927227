<template class="template">

  <body class="img-sfondo-indovina position-sticky">

    <div class="container centramento-contenuto">

      <!-- TITOLI -->
      <div class="row align-items-center justify-content-center margin-title-gioco">   
        <div class="col d-flex jusity-content-center card-titoli">
          <div class="row text-center pe-4 ps-4">

            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE CAPITALE EUROPEA</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE CAPITALE ASIATICA</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE CAPITALE AFRICANA</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>INDOVINA A QUALE STATO NORD O CENTRO AMERICANO APPARTIENE LA SEGUENTE CAPITALE</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>INDOVINA A QUALE STATO DELL'OCEANIA APPARTIENE LA SEGUENTE CAPITALE</h1>
              </div> 
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE CAPITALE SUD AMERICANA</h1>
              </div> 
              <!-- sotto titolo -->
              <h4>Devi indovinare lo stato avendo a disposizione la capitale</h4>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>GUESS WHICH EUROPEAN COUNTRY THE CAPITAL BELONGS TO</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>GUESS WHICH ASIAN COUNTRY THE CAPITAL BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>GUESS WHICH AFRICAN COUNTRY THE CAPITAL BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>GUESS WHICH NORTH OR CENTRAL AMERICAN COUNTRY THE CAPITAL BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>GUESS WHICH COUNTRY OF OCEANIA THE CAPITAL BELONGS TO</h1>
              </div>
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>GUESS WHICH SOUTH AMERICAN COUNTRY THE CAPITAL BELONGS TO</h1>
              </div>
              <!-- sotto titolo -->
              <h4>You have to guess the country having the capital available</h4>
            </div>

            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>ARVAVAA MIHIN EUROOPAN MAALE PÄÄKÄÄNTÖ KUULUU</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>ARVAVAA MIHIN AASIAAN MAALE PÄÄKÄÄNTÖ KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>ARVAVAA MIHIN AFRIKAN MAALE PÄÄKÄÄNTÖ KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>ARVAVAA MIHIN POHJOIS- JA KESKI-AMERIKAN MAAAN PÄÄKÄÄNTÖ KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>ARVAVAA MIHIN OSEANIAAN PÄÄKÄÄNTÖ KUULUU</h1>
              </div>
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>ARVAVAA MIHIN ETELÄ-AMERIKAN MAAAN PÄÄKÄÄNTÖ KUULUU</h1>
              </div>
              <!-- sotto titolo -->
              <h4>Sinun on arvattava maa, jolla on pääkaupunki oleva pääoma</h4>
            </div>

          </div>
        </div>
      </div>

      <!-- PARTE INTERATTIVA -->
      <div class="row align-items-center justify-content-center pe-3 ps-3">
        <div class="col d-flex justify-content-center card-gioco align-items-center text-center mt-5 pt-5 pb-5 me-3 ms-3">

          <!-- BOTTONE per INIZIARE -->
          <div class="row pt-5 pb-5 justify-content-center" v-if="this.a < 1">
    
            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> INIZIA </button>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese" class="row align-items-center sperim">
              <button v-on:click=" numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> START </button>
            </div>
            
            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese" class="row align-items-center sperim">
              <button v-on:click=" numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> ALKAA </button>
            </div>
      
          </div>

          <!-- CARD PAGINA FINALE -->
          <div class="row justify-content-center pt-5 pb-5" v-else-if="this.a == this.final_page">

            <!-- ITALIANO -->
            <div class="col" v-if="this.lingua == this.italiano">
              <h5>VALUTAZIONE FINALE</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Punteggio finale: </strong> {{ score }}/{{ total }} </p>
                  <p  class="score-margin"><strong>Totale risposte giuste:</strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p  class="score-margin"><strong>Livello di conoscienza:</strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Come minchia è possibile che non conosci nemmeno una capitale!?!? Sei proprio ignorante... Vai a studiare che e meglio...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Sei un ignorante!! Vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Sai qualcosina, ma ancora troppo poco, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Non sei arrivato neanche a meta, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, sei arrivatə a metà, ma non ancora alla sufficienza...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Hai una conoscienza basica della geografia, ma hai ancora molto da imparare</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Discreto, puoi fare di meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Bravo! Hai una buona conoscienza della geografia, ma puoi fare ancora meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Complimentissimi!! Hai un ottima conoscienza della geografia!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Bravissimə!! Conosci tutte le capitali di questo continente, Ottimo lavoro!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> rincomincia </button>
              </div> 
            </div>
      
            <!-- INGLESE -->
            <div class="col" v-else-if="this.lingua == this.inglese">
              <h5>FINAL EVALUATION</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Final score: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Total right answers: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Level of knowledge: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>How is it possible that you don't even know a capital!?!? You're really ignorant... Go study, it's better...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>You are ignorant!! Go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>You know a little, but still too little, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>You haven't even reached your destination, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, you're halfway there, but not enough yet...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>You have a basic understanding of geography, but you still have a lot to learn</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Decent, you can do better!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Good! You have a good understanding of geography, but you can do even better!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Congratulations!! You have an excellent knowledge of geography!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Well done!! You know all the capitals of this continent, Great job!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> start again </button>
              </div>
            </div>

            <!-- FINLANDESE -->
            <div class="col" v-else-if="this.lingua == this.finlandese">
              <h5>LOPPUARVIOINTI</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Lopullinen tulos: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Oikeat vastaukset yhteensä: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Tiedon taso: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Miten on mahdollista, ettet tiedä edes pääkaupunkia!?!? Olet todella tietämätön... Mene opiskelemaan, se on parempi...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Olet tietämätön!! Mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Tiedät vähän, mutta silti liian vähän, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Et ole edes saavuttanut määränpäätäsi, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Mh, olet puolivälissä, mutta ei vielä tarpeeksi...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Sinulla on perustiedot maantiedosta, mutta sinulla on vielä paljon opittavaa</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Kunnollinen, voit tehdä paremmin!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Hyvä! Ymmärrät maantiedon hyvin, mutta pystyt vielä parempaan!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Onnittelut!! Sinulla on erinomainen maantieteen taito!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Hyvin tehty!! Tiedät kaikki tämän mantereen pääkaupungit, hienoa työtä!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> aloittaa uudelleen </button>
              </div>
            </div>

          </div>

          <!-- GIOCO -->
          <div v-else class="container-fluid text-center pt-5 pb-5 justify-content-center">

            <!-- CARD da compilare -->
            <div class="row align-items-center justify-content-center" v-if="this.cp == true">

              <!-- EUROPA -->
              <div v-if="this.continente == this.EUROPA">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div v-for="stato in europaIT" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in europaEN" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in europaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- ASIA -->
              <div v-else-if="this.continente == this.ASIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in asiaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in asiaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in asiaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- AFRICA -->
              <div v-else-if="this.continente == this.AFRICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in africaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in africaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in africaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- NORD AMERICA -->
              <div v-else-if="this.continente == this.NORD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in nordAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in nordAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in nordAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- OCEANIA -->
              <div v-else-if="this.continente == this.OCEANIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in oceaniaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in oceaniaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in oceaniaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- SUD AMERICA -->
              <div v-else-if="this.continente == this.SUD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in sudAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in sudAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in sudAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        <!-- stato -->
                        <p class="h3 mb-4">{{ stato.Capitale.toUpperCase() }}</p>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
      
            <!-- CARD di soluzione -->
            <div class="row align-items-center justify-content-center" v-else>

              <!-- SOLUZIONE -->
              <div class="text-center container-fluid">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">SOLUZIONE: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6">complimenti la risposta era corretta!</span>
                    <p class="pt-3">punti + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorante! La risposta era sbagliata!</span>
                    <p class="pt-3">punti -5</p>
                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">CORRECT ANSWER: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">congratulations, the answer was correct!</span>
                    <p class="pt-3">points + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorant! The answer was wrong!</span>
                    <p class="pt-3">points -5</p>
                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">OIKEA VASTAUS: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">onnittelut, vastaus oli oikea!</span>
                    <p class="pt-3">pisteitä + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Tietämätön! Vastaus oli väärä!</span>
                    <p class="pt-3">pisteitä -5</p>
                  </div>
                </div>
                
              </div>

              <!-- SCORE -->
              <div class="d-flex justify-content-center align-items-center text-center ">

                <!-- ITALIANO -->
                <div class="score-border" v-if="this.lingua == this.italiano">
                  <p class="score-margin">punteggio totale: {{ score }}</p>
                  <p class="score-margin">totale risposte giuste: {{ conoscenza }}</p>
                </div>

                <!-- INGLESE -->
                <div class="score-border" v-else-if="this.lingua == this.inglese">
                  <p class="score-margin">total score: {{ score }}</p>
                  <p class="score-margin">total correct answers: {{ conoscenza }}</p>
                </div>

                <!-- FINLANDESE -->
                <div class="score-border" v-else-if="this.lingua == this.finlandese">
                  <p class="score-margin">kokonaispisteet: {{ score }}</p>
                  <p class="score-margin">täysin oikeat vastaukset: {{ conoscenza }}</p>
                </div>
                
              </div>

              <!-- BOTTONE per CAMBIARE STATO / FINE -->
              <div>
                <!-- CAMBIA STATO -->
                <div v-if="this.a < this.n_stati">
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click=" numeroCasuale(); changeState();" type="submit"> prossimo stato </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click=" numeroCasuale(); changeState();" type="submit"> next country </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> seuraava maa </button>
                  </div>
                </div>
                <!-- FINE -->
                <div v-else>
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> fine </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> end </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> loppu </button>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- A QUALE STATO SIAMO -->
            <div class="row">
              <div class="text-center container-fluid">
                <p>{{ a }}/{{ n_stati }}</p>
              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>

  </body>

</template>

<script>

  //importo il file json
  import statiItaliano from '/statiItalianoJson.json'
  import statiInglese from '/statiIngleseJson.json'
  import statiFinlandese from '/statiFinlandeseJson.json'

  export default{
    name: 'IndovinaCapitaleEuropaView',
    //importiamo la lingua
    props: ['lingua'],

    data(){
      return{

        //LINGUE
        inglese: "EN",
        italiano: "IT",
        finlandese: "FI",
        
        //DETERMINAZIONE del CONTINENTE
          //CICLARE i dati di ogni CONTINENTE
            //ITALIANO
            africaIT: statiItaliano.AFRICA,
            asiaIT: statiItaliano.ASIA,
            europaIT: statiItaliano.EUROPA,
            nordAmericaIT: statiItaliano.NORD_AMERICA,
            oceaniaIT: statiItaliano.OCEANIA,
            sudAmericaIT: statiItaliano.SUD_AMERICA,
            //INGLESE
            africaEN: statiInglese.AFRICA,
            asiaEN: statiInglese.ASIA,
            europaEN: statiInglese.EUROPA,
            nordAmericaEN: statiInglese.NORD_AMERICA,
            oceaniaEN: statiInglese.OCEANIA,
            sudAmericaEN: statiInglese.SUD_AMERICA,
            //FINLANDESE
            africaFI: statiFinlandese.AFRICA,
            asiaFI: statiFinlandese.ASIA,
            europaFI: statiFinlandese.EUROPA,
            nordAmericaFI: statiFinlandese.NORD_AMERICA,
            oceaniaFI: statiFinlandese.OCEANIA,
            sudAmericaFI: statiFinlandese.SUD_AMERICA,
          //intrappolare il parametro del continente dal file json
          AFRICA: statiItaliano.AFRICA[0].continente,
          ASIA: statiItaliano.ASIA[0].continente,
          EUROPA: statiItaliano.EUROPA[0].continente,
          NORD_AMERICA: statiItaliano.NORD_AMERICA[0].continente,
          OCEANIA: statiItaliano.OCEANIA[0].continente,
          SUD_AMERICA: statiItaliano.SUD_AMERICA[0].continente,
          //per intrappolare il parametro del continente dalle rotte
          continente: this.$route.params.continente,
        //

        //variabile per far vedere una sola card alla volta, sarà anche il contatore
        a: 0,

        //variabile per mostrare la pagina finale
        final_page: 0,

        //questa variabile racchiuderà il numero di stati del continente che stiamo usando
        n_stati: 0,
        //array vuoto per inserire gli id degli stati usciti.
        check_state_array_vuoto: [], 

        //PER SALVARE IL NUMERO CASUALE
        numero_casuale: 0,
        id_casuale: 0,

        //PUNTEGGIO
          //variabile per mostrare il punteggio
          score: 0,
          //variabile per mostrare il punteggio totale
          total: 0,
          //per dire se è giusto o sbagliato
          guess: "",
          //varibile per tenere il conto di quante risposte sono giusto
          conoscenza: 0,
          //calcola la percentuale di risposte giuste
          percentuale: 0,
        //

        //stato presa dall'input
        tempStato: "",

        //stato presa dall'oggetto
        stato: "",
        Stato: "",
        STATO: "",
        stato_speciale_1: "",
        stato_speciale_2: "",
        stato_speciale_3: "",
        stato_speciale_4: "",
        stato_speciale_5: "",
        stato_speciale_6: "",
        stato_speciale_7: "",
        stato_speciale_8: "",
        stato_speciale_9: "",

        //controlla/prossimo stato
        cp: true,

        //per avviso nel caso in cui non inserisco niente
        passa: false,
        
      }
    },

    //metodi - funzioni
    methods: {

      //CONTROLLA se la stato inserito è corretto e segna il punteggio
      checkState(){

        //fa partire la funzione solo se il campo dell'imput contiene qualcosa
        if(this.tempStato != ''){

          //controlla se la Stato inserito è uguale a tempStato (che è uguale alla Stato dello stato in questione)
          if(this.tempStato.toLowerCase() == this.stato || this.tempStato.toLowerCase() == this.stato_ || this.tempStato.toLowerCase() == this.stato_speciale_1 || this.tempStato.toLowerCase() == this.stato_speciale_2 || this.tempStato.toLowerCase() == this.stato_speciale_3 || this.tempStato.toLowerCase() == this.stato_speciale_4 || this.tempStato.toLowerCase() == this.stato_speciale_5 || this.tempStato.toLowerCase() == this.stato_speciale_6 || this.tempStato.toLowerCase() == this.stato_speciale_7 || this.tempStato.toLowerCase() == this.stato_speciale_8 || this.tempStato.toLowerCase() == this.stato_speciale_9){

            this.guess = true
          }
          else{
            this.guess = false
          } 
          
          //aggiunge i punteggi 
          if(this.guess == true){
            this.score = this.score +10
            this.conoscenza = this.conoscenza +1
          }
          else{
            this.score = this.score - 5
          }

          //cambia la variabile pc, quindi porta alla schermata per vedere la soluzione che comprende il pulsante per cambiare stato
          this.cp = !this.cp

          //si assicura che l'avviso di input vuoto non venga visualizzato
          this.passa = false
        }
        //avviso in cui l'imput sia vuoto
        else{
          this.passa = true
        }
    
      },

      //CAMBIA STATO
      changeState(){

        //per aumentare il valore di a e far scorrere gli stati 
        this.a = this.a + 1

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //per dare uno Stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.ASIA[this.id_casuale].Stato
            this.stato = statiItaliano.ASIA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.ASIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.ASIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.ASIA[this.id_casuale].Stato
            this.stato = statiInglese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiInglese.ASIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.ASIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato= statiFinlandese.ASIA[this.id_casuale].Stato
            this.stato= statiFinlandese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.stato_= statiFinlandese.ASIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //per dare uno stato a stato, Stato STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.AFRICA[this.id_casuale].Stato
            this.stato = statiItaliano.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.AFRICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.Stato_ = statiInglese.AFRICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.AFRICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.AFRICA[this.id_casuale].Stato
            this.stato = statiFinlandese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiFinlandese.AFRICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //per dare uno stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.EUROPA[this.id_casuale].Stato
            this.stato = statiItaliano.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.EUROPA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.EUROPA[this.id_casuale].Stato
            this.stato = statiInglese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiInglese.EUROPA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.EUROPA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.EUROPA[this.id_casuale].Stato
            this.stato = statiFinlandese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiFinlandese.EUROPA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.NORD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiInglese.NORD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.OCEANIA[this.id_casuale].Stato
            this.stato = statiItaliano.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.OCEANIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.OCEANIA[this.id_casuale].Stato
            this.stato = statiInglese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiInglese.OCEANIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.OCEANIA[this.id_casuale].Stato
            this.stato = statiFinlandese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiFinlandese.OCEANIA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiItaliano.SUD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiInglese.SUD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.stato_ = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato_.toLowerCase()
            this.stato_speciale_1 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }

        //cambia la variabile pc, quindi non fa vedere la schermata della soluzione fino a quando non si cliccherà di nuovo il pulsante controlla
        if(this.a > 1){
          this.cp = !this.cp
        }
        
        //salva temporaneamente la capitale inserita dall'utente
        this.tempStato = ""
      },

      //NUMERO CASUALE
      numeroCasuale(){

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){  
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }
        //mi da un numero casuale tra 1 e la lunghezza dell'array con gli stati (6)
        this.numero_casuale = Math.ceil(Math.random() * ( (this.n_stati) - (1-1)) + (1-1));

        //se l'array dove abbiamo inserito gli id degli stati usciti, include il numero casuale appena uscito allora rifacciamo partire la funzione
        if(this.check_state_array_vuoto.includes(this.numero_casuale)){
          console.log('è gia presente ritira')
          return this.numeroCasuale();
        }
        //se il numero uscito non è contenuto all'interno dell'array allora lo andiamo ad iserire
        else{
          
          //inserisco l'id dello stato estratto nell'array check_state
          this.check_state_array_vuoto.push(this.numero_casuale);
          //console.log(this.check_state_array_vuoto.sort());
          this.id_casuale = this.numero_casuale

        }
      
      },
        

      //FINIRE GIOCO
      endGame(){  
        this.stato = ""
        this.Stato = ""
        this.STATO = ""
        this.stato_speciale_1 = ""
        this.stato_speciale_2 = ""
        this.stato_speciale_3 = ""
        this.stato_speciale_4 = ""
        this.stato_speciale_5 = ""
        this.stato_speciale_6 = ""
        this.stato_speciale_7 = ""
        this.stato_speciale_8 = ""
        this.stato_speciale_9 = ""
        this.guess = ""
        this.cp = true
        this.a = this.a + 1
        //per dare un valore a final_page in modo da mostrare solo la card finale
        this.final_page = this.n_stati + 1
        //per salvare il punteggio totale
        this.total = this.n_stati * 10
        //per dare il valore ppercentuale delle risposte giuste
        this.percentuale = ((this.conoscenza/this.n_stati)*100).toFixed(0)
      },

      //RINCOMINCIA
      rincomincia(){
        //ripulisce il contatore
        this.a = 0
        //ripulisce l'array per controllare gli stati
        this.check_state_array_vuoto = [];
        this.score = 0
        this.conoscenza = 0
        this.final_page = 0
        this.total = 0
      },


      //funzione per evitare l'aggiornamento della pagina quando si cambia stato
      preventEvent(e){
        e.preventDefault();
      }
    },
    
  }

</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-indovina{
  background-image: url("../../assets/carta-da-parti-mondoblu-spazio-531.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.centramento-contenuto{
  padding-top: 7%;
  padding-bottom: 7%;
  padding-left: 5%;
  padding-right: 5%;

}

/* CARD TITOLI */
.card-titoli{ 
  background-color: rgba(189, 220, 249, 0.69);
  border: 5px solid black;
  border-radius: 70px;
  filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
}

/* PARTE GIOCABILE */
  
  /* PULSANTE INIZIA */
  .btn-custom-inizia{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 30px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    font-weight: bold;
  }
  .btn-custom-inizia:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }
  .sperim{
    min-width:max-content;
  }

  /* CARD GIOCO */
  .card-gioco{
    color:  rgba(189, 220, 249, 0.69);
    border: 5px solid rgba(189, 220, 249, 0.69);
    border-radius: 60px;
    filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
    backdrop-filter: blur(10px);
    background-color: rgb(0,0,0,0.6);
    width: 100%;
    height: 570px;
  }

  /* INPUT */
  .input-custom{
    background-color: transparent;
    border:none;
    border-bottom: 2px solid rgba(189, 220, 249, 0.69);;
    color:rgba(189, 220, 249,0.8);
    width:80%;
    text-align: center;
  }
  .input-custom::placeholder{
    color:rgba(189, 220, 249, 0.4);  
  }
  .input-custom:focus-visible{
    outline:none;
  }

  /* PULSANTI CONTROLLA, PROSSIMO STATO, FINE e RICOMINCIA */
  .btn-custom{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
  }
  .btn-custom:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }

  /* AVVISO di CONTROLLO */
  .advertise-custom{
    background-color: rgba(252, 107, 107, 0.45);
    backdrop-filter: blur(10px);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    border: 2px solid rgba(189, 220, 249, 0.69);
  }

  /* scritta soluzione */
  .orientamento-soluzione{
    display: block;
    justify-content: center;
  }

  /* AVVISO RISPOSTA CORRETTA O SBAGLIATA */
  .correct-answer{
    background-color: rgba(13, 255, 0, 0.284); 
    padding: 5px 10px 5px 10px;
  }
  .wrong-answer{
    background-color: rgba(254, 6, 2, 0.311); 
    padding: 5px 10px 5px 10px;
  }

  /* bordo punteggi */
  .score-border{
    border: 2px solid rgba(189, 220, 249, 0.69);
    max-width: max-content;
    border-radius: 50px;
  }
  .score-margin{
    margin: 10px 15px 10px 15px;
  }
/*  */



@media (min-width: 270px) { 
  .card-gioco{
  height: 530px;
  }
}
@media (min-width: 300px) {  
  .margin-title-gioco{
  padding-top: 23%;
  }
}
@media (min-width: 400px) { 
  .card-gioco{
    width: 80%;
  }
  .margin-title-gioco{
    padding-top: 20%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 470px) { 
  .margin-title-gioco{
    padding-top: 15%;
  }
}
@media (min-width: 520px) { 
  .card-gioco{
    height: 430px;
    border-radius: 100px;
  }
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  }
  .advertise-custom{
    border-radius: 100px;
  }
}
@media (min-width: 570px) { 
  .margin-title-gioco{
    padding-top: 10%;
  }
}
@media (min-width: 600px) { 
  .centramento-contenuto{
    padding-left: 5%;
    padding-right: 5%;
  }
  .orientamento-soluzione{
    display: flex;
  }
}
@media (min-width: 770px) { 
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  } 
  .margin-title-gioco{
    padding-top: 8%;
  }
  .card-titoli{
    border-radius: 80px;
  }
}
@media (min-width: 992px) { 
  .margin-title-gioco{
    padding-top: 3%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 1000px) { 
  .centramento-contenuto{
    padding-left: 15%;
    padding-right: 15%;
  }
}



</style>